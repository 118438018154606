<form #noteForm="ngForm">
  <h2 mat-dialog-title class="mat-h2">
    Lab Notes
    <button mat-icon-button class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col-12 notes" *ngIf="notes">
        <div *ngFor="let note of notes">
          <mat-label class="mat-subtitle-2 bold-title">
            {{ note?.updatedBy?.firstName }} {{ note?.updatedBy?.lastName }}:
            <span *ngIf="note.createdAt">
              {{ note.createdAt | utcToLocal : 'date' }} {{ note.createdAt | utcToLocal : 'time' }}
            </span>
          </mat-label>
          <div>
            <span class="mat-subtitle-2 notes-text">
              {{ note?.text }}
            </span>
          </div>
        </div>
        <div *ngIf="notes?.length === 0">
          <p class="truncate-label">No notes available.</p>
        </div>
      </div>
      <div class="col-12 mt-16" *ngIf="!this.userService?.roleCheck(['Reportwriter'])">
        <mat-label class="mat-subtitle-2">Additional Notes<span class="required">*</span></mat-label>
        <mat-form-field appearance="outline" class="text-area">
          <!-- TODO : Move this logic written in onInput to ts file  -->
          <textarea
            class="textarea-popup"
            matInput
            rows="3"
            name="additionalNotes"
            [(ngModel)]="additionalNotes"
            maxlength="500"
            required
            placeholder="Max 500 characters"
            (input)="onInputChange($event)"></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end" *ngIf="!this.userService?.roleCheck(['Reportwriter'])">
    <button mat-raised-button (click)="closeDialog()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!additionalNotes" (click)="addNote(); noteForm.resetForm()">
      UPDATE
    </button>
  </mat-dialog-actions>
</form>
