import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ICompany } from 'src/app/shared/models/i-company.modal';
import { SampleStatus } from 'src/app/features/dashboard/components/shipment-received/shipment-received.component';
import { PrecastService } from 'src/app/shared/services/precast.service';
import { Sample } from 'src/app/shared/models/worksheet-checkbox.model';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { PrecastSampleType, NativeSampleType } from 'src/app/shared/models/i-precast-sampletypes.modal';

@Component({
  selector: 'app-sample-information',
  templateUrl: './sample-information.component.html',
  styleUrls: ['./sample-information.component.scss'],
  providers: [DatePipe],
})
export class SampleInformationComponent implements OnInit {
  @Input() title: string;
  @Input() dataSource: any[];
  @Input() headerColumns: any[];
  @Input() displayedColumns: any[];
  @Input() link: string;
  @Input() enableEditButton: boolean | null;
  @Input() returnUrl: string | null;
  @Input() data: ICompany;
  @Input() soilType: string;
  @Input() sampleViewInfo: Sample[];
  @Input() precastSampleTypes : PrecastSampleType [] = [];
  @Input() nativeSoilSampleTypes : NativeSampleType [] = [];
  @Output() samplesChange = new EventEmitter<Sample[]>();
  sampleStatuses: SampleStatus[];
  samples: Sample[] = [];
  showHide = false;
  hideCheckbox = false;
  formGroup: FormGroup;
  userAccount: any;
  userRoles: string[] = [];

  constructor(
    private precastService: PrecastService,
    private fb: FormBuilder,
    public userService: UserService,

  ) {}

  viewForm = false;

  ngOnInit(): void {
    this.showHide = this.data?.id === null;
    if (this.data?.id) {
      this.sampleLookupStatus();
    }

    this.initFormGroup();
    if (this.soilType === 'nativesoil') {
      if (this.dataSource) {
        this.emitNativeSamples();
      }
    } else if (this.dataSource) {
      this.emitPrecastSamples();
    }
    this.subscribeToFormGroupChanges();

    if (this.dataSource) {
      this.dataSource.forEach((element) => {
        if (element.sampleStatusTypeId === null) {
          this.hideCheckbox = true;
        } else {
          this.hideCheckbox = false;
        }
      });
    }
    const editViewSubmission = sessionStorage.getItem('editViewSubmission');
    if (this.data?.id !== null && editViewSubmission === 'true') {
      this.viewForm = true;
    } else if (editViewSubmission === 'true') {
      this.viewForm = true;
    } else {
      this.viewForm = false;
    }
    this.userService.userRole?.subscribe(
      (roles) => {
        this.userRoles = roles || [];
      },
      (error) => console.error('userRole subscription error:', error),
    );

    this.userService.userAccount?.subscribe(
      (account) => {
        this.userAccount = account;
      },
      (error) => console.error('userAccount subscription error:', error),
    );
  }

  roleCheck(roles: string[]): boolean {
    if (!this.userAccount) {
      return true;
    }
    return roles.some((role) => this.userRoles.includes(role));
  }

  subscribeToFormGroupChanges() {
    this.formGroup.valueChanges.subscribe(() => {
      if (this.soilType === 'nativesoil') {
        this.emitNativeSamples();
      } else if (this.soilType === 'precast') {
        this.emitPrecastSamples();
      }
    });
  }

  // check formgroup value set(true or false) based on datasource

  initFormGroup() {
    const group: { [key: string]: FormControl } = {
    };
    if (this.dataSource && this.headerColumns) {
      this.dataSource.forEach((element) => {
        this.headerColumns.forEach((col) => {
          const controlName = this.getControlName(element, col.name);
          // group[controlName] = new FormControl(false);
          group[controlName] = new FormControl(!!this.getInitialCheckboxValue(element, col.name));
        });
      });
    }
    this.formGroup = this.fb.group(group);
  }

  getInitialCheckboxValue(element: any, columnName: string): boolean {
    const samples = this.sampleViewInfo?.find((sample) => sample.sampleIdName === element.sampleId);
    if (samples) {
      switch (columnName) {
        case 'sampletype':
          return !!samples.sampleType;
        case 'displayDepth':
          return !!samples.sampleDepth;
        case 'moistureContent':
          return !!samples.moistureOptimum;
        default:
          return !!samples[columnName];
      }
    }
    return false;
  }

  sampleLookupStatus() {
    this.precastService.getSampleStatusLookup().subscribe({
      next: (res: SampleStatus[]) => {
        this.sampleStatuses = res;
      },
      error: (error) => {
        console.error('Error fetching sample statuses:', error);
      },
    });
  }

  getSampleTypeName(id: string): string {
    if (this.soilType === 'precast') {
      return this.getPrecastSampleTypeName(id);
    }
    return this.getNativeSampleTypeName(id);
    return '';
  }

  getPrecastSampleTypeName(id: string): string {
    const item = this.precastSampleTypes.find((entry:PrecastSampleType) => entry.id === id);
    return item ? item.label : '';
  }

  getNativeSampleTypeName(id: string): string {
    const item = this.nativeSoilSampleTypes.find((entry:NativeSampleType) => entry.id === id);
    return item ? item.label : '';
  }

  getSampleStatusLabel(id: string): string {
    if (!id || !this.sampleStatuses) {
      return '';
    }

    const status = this.sampleStatuses.find((statusId) => statusId.id === id.toString());
    return status ? status.label : '';
  }

  // particular column set background color based on colname
  getCellClass(colName: string, element: any): string {
    if (colName === 'sampleStatusTypeId') {
      switch (this.getSampleStatusLabel(element[colName])) {
        case 'Damaged':
          return 'row-white';
        case 'Missing':
          return 'row-white';
        case 'Received':
          return 'row-white';
        default:
          return 'row-white';
      }
    }
    return '';
  }

  // shouldShowCheckbox(element: any, col: any): boolean {
  //   return !!element[col.name];
  // }
  shouldShowCheckbox(): boolean {
    return this.roleCheck(['Admin', 'Manager']);
  }

  getControlName(element: any, colName: string): string {
    return `${element.id}_${colName}`;
  }

  getDisplayDepth(item: any): string {
    if (item.stockPile && item.displayDepth === `${null}-${null}`) {
      return 'Stockpile';
    }
    if (item.displayDepth === `${null}-${null}`) {
      return '';
    }
    return item.displayDepth;
  }

  // background color set based on API response value samplestatusTypeId
  getRowClasses(row: any): any {
    return {
      'received-row': row.sampleStatusTypeId === 'd471cab0-df24-4a80-a670-9994fbdf5f6f',
      'missing-row': row.sampleStatusTypeId === '2320323b-65e5-4270-95ce-6fa221d3c0b5',
      'damaged-row': row.sampleStatusTypeId === '33de1f0d-8209-4d52-a4c7-18e4918d9932',
    };
  }
  // emit the native soil checkbox value

  emitNativeSamples() {
    this.samples = [];
    this.dataSource.forEach((element) => {
      const sample: Sample = {
        sampleIdName: element.sampleId || '',
        sampleColDate: element.collectionDate || null,
        sampleTypeData: element.sampletype || '',
        sampleStatusTypeId: element.sampleStatusTypeId || '',
        stockPile: element.stockPile || false,
        sampleDepthData: `${element.sampleDepth}-${element.sampleEndDepth}` || '',
        sampleId: !!this.formGroup.get(`${element.id}_sampleId`)?.value || false,
        sampleType: !!this.formGroup.get(`${element.id}_sampletype`)?.value || false,
        sampleDepth: !!this.formGroup.get(`${element.id}_displayDepth`)?.value || false,
        collectionDate: !!this.formGroup.get(`${element.id}_collectionDate`)?.value || false,
        soilDescription: !!this.formGroup.get(`${element.id}_soilDescription`)?.value || false,
        maxDryDensity: !!this.formGroup.get(`${element.id}_maxDryDensity`)?.value || false,
        optimumMoistureContent: !!this.formGroup.get(`${element.id}_optimumMoistureContent`)?.value || false,
        compactionEffort: !!this.formGroup.get(`${element.id}_compactionEffort`)?.value || false,
        moistureOptimum: !!this.formGroup.get(`${element.id}_moistureContent`)?.value || false,
      };

      this.samples.push(sample);
    });
    this.samplesChange.emit(this.samples);
  }

  // emit the precast soil checkbox value
  emitPrecastSamples() {
    this.samples = [];
    this.dataSource.forEach((element) => {
      const sample: Sample = {
        sampleIdName: element.sampleId || '',
        sampleColDate: element.sampleCollectionDate || null,
        sampleTypeData: element.sampleType || '',
        sampleStatusTypeId: element.sampleStatusTypeId || '',
        sampleId: !!this.formGroup.get(`${element.id}_sampleId`)?.value || false,
        sampleType: !!this.formGroup.get(`${element.id}_sampleType`)?.value || false,
        sampleCollectionDate: !!this.formGroup.get(`${element.id}_sampleCollectionDate`)?.value || false,
        mixDesignCode: !!this.formGroup.get(`${element.id}_mixDesignCode`)?.value || false,
        numSamplesPerSet: !!this.formGroup.get(`${element.id}_numSamplesPerSet`)?.value || false,
        truckNumber: !!this.formGroup.get(`${element.id}_truckNumber`)?.value || false,
        ticketNumber: !!this.formGroup.get(`${element.id}_ticketNumber`)?.value || false,
      };
      this.samples.push(sample);
    });
    this.samplesChange.emit(this.samples);
  }
}
