import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ChartOptions, ChartDataset, Chart } from 'chart.js';
import { DatePipe } from '@angular/common';
import { IPoints } from 'src/app/shared/models/graph-points.modal';
import { IADDRESS } from 'src/app/shared/models/i-address.modal';
import { REPORTPREVIEW } from 'src/app/shared/models/i-report-preview.modal';
import { ISIGNATURE } from 'src/app/shared/models/i-signature.modal';
import { SoilType } from 'src/app/shared/constants/soil-type.constants';
import { SharedService } from 'src/app/features/service/shared.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  providers: [DatePipe],
})
export class PreviewComponent implements OnInit {
  @ViewChildren('canvasRef') canvasRefs: QueryList<ElementRef<HTMLCanvasElement>>;
  @Output() getImageCount: EventEmitter<number> = new EventEmitter<number>();
  chart: Chart;
  previewData: REPORTPREVIEW;
  @Input() set preview(value: REPORTPREVIEW) {
    this.previewData = value;
    if (this.previewData) {
      this.geothermComment = this.previewData.geothermComment
        ? this.sanitizer.bypassSecurityTrustHtml(this.previewData.geothermComment)
        : null;

      this.recommendations = this.previewData.recommendations
        ? this.sanitizer.bypassSecurityTrustHtml(this.previewData.recommendations)
        : null;
    }
  }

  @Input() address: IADDRESS;
  @Input() signature: ISIGNATURE;
  @Input() soilType: string;
  @Input() PONumber: string;
  @Input() projectId: string;
  public lineChartData: ChartDataset[] = [];
  public lineChartDataArray: any[] = [];
  public textDataArray: any[] = [];
  figureCount = 0;
  data: any;
  figures: any[] = [];
  leftBorderColor = ['#E43131', '#3FC828', '#19B7E9', '#AD1CE0', '#9F3A0F', '#000000'];
  geothermComment: SafeHtml | null = null;
  recommendations: SafeHtml | null = null;
  constructor(
    private reportService: ReportService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.getGraphData();
    this.sharedService.soilTypes(this.soilType);
  }

  getGraphData(): void {
    this.reportService.getGraphData(this.projectId, this.soilType)?.subscribe({
      next: (data) => {
        this.figureCount = 0;
        const lineChartDataArray: any[] = [];
        const textDataArray: any[] = [];
        const textForDraw: any[] = [];
        let colorIndex = 0;
        this.data = data;
        const sampleIDs = this.sharedService.splitFigureLogic(data, this.soilType);
        if (typeof sampleIDs !== 'object' || sampleIDs === null) {
          this.figureCount = 0; // Or handle as appropriate
          return; // Early exit if sampleIDs is invalid
        }
        this.figureCount = Object.keys(sampleIDs).length;
        data.forEach((graphDataArray: any[], i: number) => {
          const graphPoints: IPoints[] = [];
          graphDataArray.reverse();
          let count = 0;
          Object.keys(sampleIDs)
            .sort()
            .forEach((sampleID) => {
              count += sampleIDs[sampleID];
              if (i === count) {
                colorIndex = 0;
              }
            });
          this.lineChartData.push({
            type: 'line',
            data: [],
            tension: 0.3,
            borderColor: this.leftBorderColor[colorIndex],
          });
          if (this.soilType === SoilType.Native) {
            // eslint-disable-next-line no-nested-ternary
            const depthString = graphDataArray[0]?.stockPile
              ? ' - Stockpile'
              : graphDataArray[0]?.depth
                ? graphDataArray[0]?.endDepth
                  ? ` - ${graphDataArray[0]?.depth}'-${graphDataArray[0]?.endDepth}'`
                  : ` - ${graphDataArray[0]?.depth}`
                : '';
            textForDraw.push({
              name: graphDataArray[0]?.sampleId.substring(0, 6),
              lineText: `${graphDataArray[0].sampleId} - @${graphDataArray[0]?.effort}%${depthString}`,
            });
          } else if (this.soilType === SoilType.Precast) {
            const dateString = this.datePipe.transform(
              new Date(new Date(graphDataArray[0]?.castDate).getTime() - new Date().getTimezoneOffset() * 60000),
              'MM/dd/yy',
            );
            textForDraw.push({
              name: graphDataArray[0]?.sampleId.substring(0, 6),
              lineText: `${graphDataArray[0].inHouseId} - ${dateString} - ${graphDataArray[0]?.sampleType}`,
            });
          }
          graphDataArray.forEach((each) => {
            if (this.soilType === SoilType.Native) {
              if (graphDataArray[0].worksheetType === 'Bulk') {
                graphPoints.push({
                  x: each.labSpecMoistureContent,
                  y: each.wetTr,
                });
              } else {
                graphPoints.push({
                  x: each.moistureContent,
                  y: each.wetTr,
                });
              }
            } else if (this.soilType === SoilType.Precast) {
              graphPoints.push({
                x: each.averageMoisture,
                y: each.averageWetTr,
              });
            }
          });
          graphPoints.unshift({
            x: 0,
            y: graphDataArray[0]?.dryTr,
          });
          this.lineChartData[i].data = graphPoints;
          colorIndex += 1;
        });
        let lineDataArray: any[] = [];
        let textArray: any[] = [];
        let count = 0;
        Object.keys(sampleIDs)
          .sort()
          .forEach((sampleID) => {
            lineDataArray = this.lineChartData.slice(count, count + sampleIDs[sampleID]);
            lineChartDataArray.push([...lineDataArray]);
            textArray = textForDraw.slice(count, count + sampleIDs[sampleID]);
            textDataArray.push([...textArray]);
            count += sampleIDs[sampleID];
          });
        this.lineChartDataArray = lineChartDataArray;
        const imageCount = lineChartDataArray.length;
        this.getImageCount.emit(imageCount);
        setTimeout(() => {
          this.canvasRefs?.forEach((canvasRef, i) => {
            const canvas = canvasRef.nativeElement;
            canvas.width = 800;
            canvas.height = 800;
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.fillStyle = 'rgba(0, 0, 255, 0.2)';
              ctx.fillRect(10, 10, 800, 800);
              this.chart = new Chart(ctx, {
                type: 'scatter',
                data: {
                  datasets: lineChartDataArray[i],
                },
                options: this.setChartOptions(textDataArray[i]),
                plugins: [],
              });
              // imageCount += 1;
            }
          });
        }, 50);
      },
      error: () => {},
    });
  }

  findMax(old: number, latest: number): number {
    return old < latest ? latest : old;
  }

  setChartOptions(textDataArray: any): ChartOptions {
    return {
      maintainAspectRatio: true,
      aspectRatio: 1,
      elements: {
        point: {
          radius: 0,
        },
      },
      plugins: {
        tooltip: {
          enabled: true,
          displayColors: false,
        },
        legend: {
          // title: {
          //   text: 'CONCRETE',
          // },
          maxWidth: 100,
          position: 'bottom',
          align: 'center',
          onClick: () => {},
          labels: {
            generateLabels() {
              const legends: any[] = [];
              const colors = ['#E43131', '#3FC828', '#19B7E9', '#AD1CE0', '#9F3A0F', '#000000'];
              textDataArray.forEach((textData: any, i: number) => {
                legends.push({
                  text: textData.lineText,
                  strokeStyle: colors[i],
                });
              });
              return legends;
            },
            boxHeight: 1,
            boxWidth: 50,
            textAlign: 'center',
          },
        },
      },
      scales: {
        y: {
          min: 0,
          max: Math.max(this.checkLegendChange() * 50,150),
          title: {
            display: true,
            text: 'THERMAL RESISTIVITY (°C -cm/W)',
            color: 'black',
            font: {
              weight: 'bold',
            },
          },

          ticks: {
            stepSize: 10,
            autoSkip: false,
            callback: (value, index) => (index % 5 === 0 ? value : ''),
            major: {
              enabled: true,
            },
            color: 'black',
          },
        },
        x: {
          min: 0,
          max: Math.max(this.checkLegendChange() * 5,5),
          title: {
            display: true,
            text: 'MOISTURE CONTENT (% DRY WEIGHT)',
            color: 'black',
            font: {
              weight: 'bold',
              style: 'normal',
            },
          },
          ticks: {
            stepSize: 1,
            autoSkip: false,
            callback: (value, index) => (index % 5 === 0 ? value : ''),
            color: 'black',
          },
        },
      },
    };
  }

  checkLegendChange(): number {
    let maxTr = 0;
    let maxMc = 0;
    this.data.forEach((control: any[]) => {
      control.forEach((each: any, j: number) => {
        if (j === 0) {
          maxTr = this.findMax(maxTr, each.dryTr);
        }
        maxTr = this.findMax(maxTr, each.wetTr);
        if (this.soilType === SoilType.Native) {
          if (control[0].worksheetType === 'Bulk') {
            maxMc = this.findMax(maxMc, each.labSpecMoistureContent);
          } else {
            maxMc = this.findMax(maxMc, each.moistureContent);
          }
        } else if (this.soilType === SoilType.Precast) {
          maxMc = this.findMax(maxMc, each.averageMoisture);
        }
      });
    });
    maxTr = Number(maxTr);
    if (maxTr / 50 > maxMc / 5) {
      return maxTr % 50 === 0 ? maxTr / 50 : Math.ceil(maxTr / 50);
    }
    return maxMc % 5 === 0 ? maxMc / 5 : Math.ceil(maxMc / 5);
  }

  // unit test case purpose
  getSanitizedGeothermComment(comment: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(comment);
  }

  getSanitizedRecommendations(recommendations: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(recommendations);
  }
}
