import { Component, Output, EventEmitter, OnInit, Input, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPrecastSample, IPrecastSampleData } from 'src/app/shared/models/i-precast-sample.modal';
import { ICompany } from 'src/app/shared/models/i-company.modal';
import { samplePerSetValidator } from 'src/app/shared/validators/sample-per-set-range.validator';
import { PrecastSampleType } from 'src/app/shared/models/i-precast-sampletypes.modal';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { messagesConfig } from '../../../../shared/constants/message.constants';
import { DialogService } from '../../../../shared/services/dialog.service';

@Component({
  selector: 'app-sample-data-precast',
  templateUrl: 'sample-data-precast.component.html',
  styleUrls: ['sample-data-precast.component.scss'],
})
export class SampleDataPrecastComponent implements OnInit, OnChanges {
  @Output() sampleDataPrecastData: EventEmitter<IPrecastSampleData> = new EventEmitter();
  @Output() sampleTypeSelected: EventEmitter<string[]> = new EventEmitter();
  @Input() data: any;
  makeSampleEditable = true;
  @Input() set makeEditable(value: boolean) {
    this.makeSampleEditable = value;
  }

  @Input() sampleTypes: PrecastSampleType[] = [];

  @Input() companyData: ICompany | undefined;

  sampleDataPrecastForm: FormGroup;
  currentDate = new Date(Date.now());
  readytoEmit = false;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    public cdr: ChangeDetectorRef,
  ) {
    this.sampleDataPrecastForm = this.fb?.group({
      samples: this.fb?.array([]),
    });
  }

  ngOnInit(): void {
    this.sampleDataPrecastForm?.valueChanges?.subscribe((frmGrp) => {
      if (this.readytoEmit) {
        this.sampleDataPrecastData?.emit({
          value: frmGrp?.samples,
          valid: this.sampleDataPrecastForm?.valid,
          dirty: this.sampleDataPrecastForm?.dirty,
        });
      }
    });
    if (this.data) {
      const sampleLength = this.data.length;
      if (sampleLength > 0) {
        for (let i = 0; i < sampleLength; i++) {
          this.getSampleControls.push(this.createItem());
        }
      }
      const patchValue = {
        samples: this.data,
      };
      this.sampleDataPrecastForm.patchValue(patchValue);
    } else {
      this.getSampleControls.push(this.createItem());
    }
    this.readytoEmit = true;
    this.sampleDataPrecastData?.emit({
      value: this.sampleDataPrecastForm.get('samples')?.value,
      valid: this.sampleDataPrecastForm?.valid,
      dirty: this.sampleDataPrecastForm?.dirty,
    });
  }

  public addSample(): void {
    if (this.getSampleControls?.length < 50) {
      this.getSampleControls?.push(this.createItem());
      this.sampleDataPrecastForm.markAsUntouched();
      this.sampleDataPrecastForm.updateValueAndValidity();
    } else {
      this.dialogService?.open(messagesConfig?.maxSampleWarning);
    }
    this.cdr.detectChanges();
  }

  toggleButtonState(): void {
    this.addSample();
  }

  public removeItem(index: number) {
    this.getSampleControls?.removeAt(index);
    this.processSampleType();
  }

  getErrorMessage(index: number) {
    const control = this.getSampleControls.at(index).get('numSamplesPerSet');
    if (control?.hasError('required')) {
      return 'This field is required.';
    }
    if (control?.hasError('outOfRange')) {
      return 'The value must be between 1-3.';
    }
    return '';
  }

  private createItem(): FormGroup {
    return this.fb?.group({
      id: [null],
      sampleId: ['', Validators.required],
      truckNumber: [''],
      ticketNumber: [''],
      sampleCollectionDate: ['', Validators.required],
      sampleTypeMasterId: ['', Validators.required],
      numSamplesPerSet: [
        {
          value: '',
          disabled: !this.makeSampleEditable,
        },
        [Validators.required, samplePerSetValidator()],
      ],
      mixDesignCode: [
        {
          value: '',
          disabled: !this.makeSampleEditable,
        },
      ],
      thermalSpecifications: [
        {
          value: '',
          disabled: !this.makeSampleEditable,
        },
      ],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['makeEditable']) {
      this.updateFieldStates();
    }
  }

  private updateFieldStates(): void {
    const { controls } = this.getSampleControls;
    controls.forEach((control) => {
      if (this.makeSampleEditable) {
        control.get('numSamplesPerSet')?.enable();
        control.get('mixDesignCode')?.enable();
        control.get('thermalSpecifications')?.enable();
      } else {
        control.get('numSamplesPerSet')?.disable();
        control.get('mixDesignCode')?.disable();
        control.get('thermalSpecifications')?.disable();
      }

      control.get('numSamplesPerSet')?.updateValueAndValidity();
      control.get('mixDesignCode')?.updateValueAndValidity();
      control.get('thermalSpecifications')?.updateValueAndValidity();
    });

    this.cdr.detectChanges();
  }

  get getSampleControls(): FormArray {
    return this.sampleDataPrecastForm?.controls['samples'] as FormArray;
  }

  datechange(data: Date, i: number): void {
    const controlArray: FormArray = <FormArray> this.sampleDataPrecastForm?.get('samples');
    controlArray?.controls[i]?.get('sampleCollectionDate')?.setValue(data);
  }

  sampleTypeChange(): void {
    this.processSampleType();
  }

  processSampleType(): void {
    const selectedSampleType: any[] = [];
    this.sampleDataPrecastForm.value.samples.forEach((sample: IPrecastSample) => {
      const isDuplicate = selectedSampleType.some(
        (item) => item.sampleTypeMasterId === sample.sampleTypeMasterId,
      );

      if (!isDuplicate) {
        selectedSampleType.push({
          id: '',
          projectId: '',
          sampleTypeMasterId: sample.sampleTypeMasterId,
          projectComment: '',

        });
      }
    });
    setTimeout(() => {
      this.sampleTypeSelected.emit(selectedSampleType);
    }, 10);
  }
}
