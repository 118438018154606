import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { IUser } from 'src/app/features/models/iuser.modal';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { WorksheetService } from 'src/app/features/worksheet/services/worksheet.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/shared/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-assign-to-labtech',
  templateUrl: './assign-to-labtech.component.html',
  styleUrls: ['./assign-to-labtech.component.scss'],
})
export class AssignToLabtechComponent implements OnInit, OnDestroy {
  public config: any;
  public additionalNotes: string | undefined;
  public assignToLabtechForm!: FormGroup;
  public userDropDown: IUser[] = [];
  public assignToLabtechSubscription = Subscription.EMPTY;
  public assignToAllWorksheet = false;
  public assignTypeProject = false;

  constructor(
    public matDialogRef: MatDialogRef<AssignToLabtechComponent>,
    @Inject(MAT_DIALOG_DATA) data: MatDialog,
    public userService: UserService,
    private formBuilder: FormBuilder,
    public worksheetService: WorksheetService,
    private snackBar: MatSnackBar,
  ) {
    this.config = data;
    this.assignTypeProject = 'worksheetId' in this.config;
  }

  ngOnInit(): void {
    this.fetchLabTechs();
    this.assignToLabtechForm = this.formBuilder.group({
      userId: ['', [Validators.required]],
      assignAll: [false],
      role: [''],
      additionalNotes: [null],
    });
  }

  assignToLabtech(): void {
    if (this.assignToLabtechForm.valid) {
      const assignedUser = this.assignToLabtechForm.value;
      const adUser = this.userDropDown.find((f) => f.id === assignedUser.userId);
      let payload;
      if ('worksheetId' in this.config) {
        payload = {
          worksheetRowId: this.config.worksheetId.id,
          aadObjectId: adUser?.id,
          firstName: adUser?.givenName,
          lastName: adUser?.surname,
          userName: adUser?.displayName,
          email: adUser?.userPrincipalName,
          role: adUser?.role,
          assignAll: assignedUser.assignAll,
          additionalNotes: assignedUser.additionalNotes === '' ? null : assignedUser.additionalNotes,
        };
        this.assignToLabtechSubscription = this.worksheetService.assignToLabtech(payload, this.config?.type).subscribe({
          next: () => {
            this.snackBar.open(messagesConfig.assignTolabTech.message, 'DONE', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['default-snackbar'],
            });
            this.closeDialog(true);
          },
          error: () => {},
        });
      } else {
        payload = {
          projectId: this.config.projectId,
          aadObjectId: adUser?.id,
          firstName: adUser?.givenName,
          lastName: adUser?.surname,
          userName: adUser?.displayName,
          email: adUser?.userPrincipalName,
          role: adUser?.role,
          additionalNotes: assignedUser.additionalNotes === '' ? null : assignedUser.additionalNotes,
        };
        this.assignToLabtechSubscription = this.worksheetService.assignProject(payload, this.config?.type).subscribe({
          next: (userDetails: any) => {
            this.snackBar.open(messagesConfig.changeSuccessMeaage.message, 'DONE', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['default-snackbar'],
            });
            this.closeDialog(userDetails);
          },
          error: () => {},
        });
      }
    }
  }

  closeDialog(assigned: boolean): void {
    this.matDialogRef.close(assigned);
  }

  fetchLabTechs(): void {
    const labTechUsers$ = this.userService.getUsersByGroupIdGraphApi(environment.labTechGroupId);
    const labManagerUsers$ = this.userService.getUsersByGroupIdGraphApi(environment.labManagerGroupId);
    const reportWriterUsers$ = this.userService.getUsersByGroupIdGraphApi(environment.reportWriterGroupId);
    const adminUsers$ = this.userService.getUsersByGroupIdGraphApi(environment.adminGroupId);
    let userGroupBasedOnRole: Observable<any>[] = [];
    if ('worksheetId' in this.config) {
      if (this.userService.roleCheck(['Manager'])) {
        userGroupBasedOnRole = [labTechUsers$, labManagerUsers$];
      } else if (this.userService.roleCheck(['Labtech'])) {
        userGroupBasedOnRole = [labManagerUsers$];
      } else if (this.userService.roleCheck(['Admin'])) {
        userGroupBasedOnRole = [labTechUsers$, labManagerUsers$];
      }
    } else {
      userGroupBasedOnRole = [reportWriterUsers$, adminUsers$];
    }

    combineLatest(userGroupBasedOnRole).subscribe({
      next: ([labtechs, labMangers]) => {
        this.userDropDown = [
          ...labtechs.value.map((user: IUser) => {
            user.role = this.checkAndAssignRole(user);
            return user;
          }),
          ...(labMangers
            ? labMangers.value.map((user: IUser) => {
              user.role = this.checkAndAssignRole(user);
              return user;
            })
            : []),
        ];
      },
    });
  }

  checkAndAssignRole(user: IUser): string {
    if (user.jobTitle === 'Lab Technician') {
      return 'Labtech';
    }
    if (user.jobTitle === 'Lab Manager') {
      return 'Manager';
    }
    if (user.jobTitle === 'Report Writer') {
      return 'Reportwriter';
    }
    if (user.jobTitle === 'Administrator') {
      return 'Admin';
    }
    return '';
  }

  onInputChange(event: any): void {
    let currentValue = event.target.value;
    if (currentValue.startsWith(' ')) {
      event.preventDefault();
      this.assignToLabtechForm.get('additionalNotes')?.setValue(currentValue.slice(1));
      return;
    }
    currentValue = currentValue.replace(/[^A-Za-z0-9-,.;'&/.() @#$%^*!?_+=|~`{}[\]<>:;\\ ]+/g, ' ');
    if (currentValue.trim() === '') {
      this.assignToLabtechForm.get('additionalNotes')?.setValue('');
    } else {
      this.assignToLabtechForm.get('additionalNotes')?.setValue(currentValue);
    }
  }
  
  

  ngOnDestroy() {
    this.assignToLabtechSubscription.unsubscribe();
  }
}
