import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ICommentData, ICommentModel } from 'src/app/shared/models/i-comment-modal';
import { PrecastSampleType } from 'src/app/shared/models/i-precast-sampletypes.modal';

@Component({
  selector: 'app-requirement-comment',
  templateUrl: 'requirement-comment.component.html',
  styleUrls: ['requirement-comment.component.scss'],
})
export class RequirementCommentComponent implements OnInit {
  @Output() requirementData: EventEmitter<ICommentData> = new EventEmitter();
  @Input() data: ICommentModel[] = [];
  @Input() submissionType: string;
  @Input() precastSampleTypes:PrecastSampleType[] = [];
  requirementCommentForm: FormGroup;
  constructor(private fb: FormBuilder) {
    this.requirementCommentForm = this.fb.group({
      comments: this.fb.array([]),
    });
  }

  createItem(): FormGroup {
    return this.fb.group({
      id: [null],
      projectId: [null],
      sampleTypeMasterId: [null],
      projectComment: [],
    });
  }

  get comments(): FormArray {
    return this.requirementCommentForm.get('comments') as FormArray;
  }

  ngOnInit(): void {
    if (this.data && this.data.length) {
      this.data.forEach(() => this.addComment());
    }

    this.requirementCommentForm.patchValue({
      comments: this.data,
    });
    this.requirementCommentForm?.valueChanges?.subscribe((frmGrp) => {
      this.requirementData?.emit({
        valid: this.requirementCommentForm?.valid,
        dirty: this.requirementCommentForm?.dirty,
        value: frmGrp.comments,
      });
    });
  }

  clearComments(): void {
    const commentsArray = this.requirementCommentForm.get('comments') as FormArray;
    commentsArray.clear();
  }

  addComment(): void {
    const commentsArray = this.requirementCommentForm.get('comments') as FormArray;
    commentsArray.push(this.createItem());
  }

  getSampleTypeName(id: string): string {
    const item = this.precastSampleTypes.find((entry:PrecastSampleType) => entry.id === id);
    return item ? item.label : '';
  }

  getOtherTypeId(): string {
    return 'a';
  }
}
