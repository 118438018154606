import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { MatDividerModule } from '@angular/material/divider';
import { NgChartsModule } from 'ng2-charts';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReportRoutingModule } from './report-routing.module';
import { ViewReportComponent } from './components/view-report/view-report.component';
import { EditReportComponent } from './components/edit-report/edit-report.component';
import { PreviewComponent } from './components/preview/preview.component';
import { PrecastSampleComponent } from './components/precast-sample/precast-sample.component';
import { NativeSampleComponent } from './components/native-sample/native-sample.component';
import { ThermalResistivityComponent } from './components/thermal-resistivity/thermal-resistivity.component';
import { MlErrorComponent } from './components/ml-error/ml-error.component';
import { RecommendationComponent } from './components/recommendation/recommendation.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    ViewReportComponent,
    EditReportComponent,
    PreviewComponent,
    PrecastSampleComponent,
    NativeSampleComponent,
    ThermalResistivityComponent,
    MlErrorComponent,
    RecommendationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    ReportRoutingModule,
    NgChartsModule,
    MatSidenavModule,
    MatDialogModule,
    MatDividerModule,
    MatRadioModule,
    MatTableModule
  ],
})
export class ReportModule { }
