<div class="shade-white review-each-wrapper mat-elevation-z3" *ngIf="(enableEditButton && hideShow) || viewForm">
  <div class="row">
    <div class="col-md-11">
      <div class="block-wrapper">
        <h2>{{ title }}</h2> 
      </div>
    </div>
  </div>
  <div class="row review-instruction-wrapper">
    <div class="block-wrapper" *ngFor = "let comment of data.comments">
      <h4 class="bold">{{getPrecastSampleTypeName(comment.sampleTypeMasterId)}}</h4>
      <p>{{ comment.projectComment }}</p>
    </div>    
  </div>
</div>
<!-- new view submission form  include checkbox-->

<div *ngIf="soiltype">
  <div class="shade-white review-each-wrapper mat-elevation-z3" *ngIf="(enableEditButton && hideShow) || !viewForm">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-md-11">
          <div class="block-wrapper">
            <h2>{{ title }}</h2>
          </div>
        </div>
        <div
          class="no-print col-md-1 btn-wrap r-edit-wraper"
          formGroupName="commentInfo"
          *ngIf="enableEditButton && !hideShow && !viewForm">
          <mat-checkbox color="primary" formControlName="comments"> </mat-checkbox>
        </div>
      </div>
    </form>
    <div class="row review-instruction-wrapper">
      <div class="block-wrapper" *ngFor = "let comment of data.comments">
        <h4 class="bold">{{getPrecastSampleTypeName(comment.sampleTypeMasterId)}}</h4>
        <p>{{ comment.projectComment }}</p>
      </div>    
    </div>
  </div>
</div>