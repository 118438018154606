import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatOptionModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { EditNativeSoilWorksheetComponent } from './components/edit-native-soil-worksheet/edit-native-soil-worksheet.component';
import { EditPrecastWorksheetComponent } from './components/edit-precast-worksheet/edit-precast-worksheet.component';
import { AssignToLabtechComponent } from '../../shared/components/assign-to-labtech/assign-to-labtech.component';
import { NativesoilTableComponent } from './components/nativesoil-table/nativesoil-table.component';
import { PrecastTableComponent } from './components/precast-table/precast-table.component';
import { WorksheetRoutingModule } from './worksheet-routing.module';
import { WorksheetComponent } from './worksheet.component';
import { SharedModule } from '../../shared/shared.module';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    WorksheetComponent,
    EditPrecastWorksheetComponent,
    AssignToLabtechComponent,
    PrecastTableComponent,
    NativesoilTableComponent,
    EditNativeSoilWorksheetComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatTableModule,
    MatTooltipModule,
    WorksheetRoutingModule,
    MatIconModule,
    MatFormFieldModule,
    MatDividerModule,
    MatTabsModule,
    MatDialogModule,
    MatCardModule,
    MatOptionModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatChipsModule,
  ],
})
export class WorksheetModule {}
