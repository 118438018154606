/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { ChangeDetectorRef, Component, Input, AfterViewChecked, OnInit, ViewChild } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Subscription, take } from 'rxjs';
import { IInstructionModel } from 'src/app/shared/models/i-instruction-modal';
import { ICommentData, ICommentModel } from 'src/app/shared/models/i-comment-modal';
import { IDropdown } from 'src/app/shared/models/i-dropdown-type.modal';
import { IPrecastRecord } from 'src/app/shared/models/i-precast-record.modal';
import { FormService } from 'src/app/shared/services/form.service';
import { INativeSample, INativeSampleData } from 'src/app/shared/models/i-native-sample.modal';
import { IPrecastSample, IPrecastSampleData } from 'src/app/shared/models/i-precast-sample.modal';
import { PrecastService } from 'src/app/shared/services/precast.service';
import { PrecastSampleType, NativeSampleType } from 'src/app/shared/models/i-precast-sampletypes.modal';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { HttpParams } from '@angular/common/http';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MessageModalComponent } from '../../shared/components/message-modal/message-modal.component';
import { ICompany, ICompanyData } from '../../shared/models/i-company.modal';
import { UserService } from '../../shared/services/user.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'app-sample-collection-form',
  templateUrl: './sample-collection-form.component.html',
  styleUrls: ['./sample-collection-form.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        displayDefaultIndicatorType: false,
      },
    },
  ],
})
export class SampleCollectionFormComponent implements OnInit, AfterViewChecked {
  @ViewChild('stepper') stepper: MatStepper;
  @Input() routedFrom: string;
  public sampleSubmissionSuccess: MatDialogRef<MessageModalComponent>;
  public pageLeaveDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  public sampleDataPrecast: IPrecastSample[];
  public sampleDataNative: INativeSample[];
  public instructions: IInstructionModel;
  instructionsChangedValue: IInstructionModel | null;
  public company: ICompany;
  public selectedSampleTypes: string[];
  public submissionType: string;
  public sampleDataPrecastValid: boolean;
  public sampleDataNativeValid: boolean;
  public precastSampleDirty: boolean;
  public instructionsValid: boolean;
  public instructionsDirty: boolean;
  public nativeSampleDirty: boolean;
  public companyValid: boolean;
  public companyDirty: boolean;
  public projectDirty: boolean;
  public userAccount: any;
  companyInformation: ICompany;
  soilData: IPrecastSample | INativeSample;
  requirementComment: ICommentModel [] = [];
  subscription: Subscription;
  projectId: string | null;
  checkLoadForm = true;
  savedFormData: IPrecastRecord;
  isFormChanged = false;
  showForm = false;
  initialStep = true;
  commentsValid = false;
  stepperIndex = 0;
  fromWhere = 'd';
  projectStatuses: IDropdown[] = [];
  makeSampleEditable = true;
  data: IPrecastRecord | null;
  preCastSampleTypes: PrecastSampleType [] = [];
  commentStepperCheck = false;
  nativeSampleType : NativeSampleType[] = [];

  constructor(
    public formService: FormService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    public cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private router: Router,
    private precastService: PrecastService,
    private sharedService:SharedService,
    private lookupService: LookupService,
  ) {}

  ngOnInit(): void {
    this.getSampleTypeLookUp();
    this.getNativeSampleTypes();
    if (this.precastService.projectStatuses) {
      this.setProjectStatusArray(this.precastService.projectStatuses);
    }
    this.submissionType = this.activatedRoute?.snapshot?.paramMap?.get('sampletype') || '';
    this.userService?.userAccount?.subscribe({
      next: (account) => {
        this.userAccount = account;
        if (!this.userService.captchaValidated) {
          if (!this.userAccount) {
            this.router.navigate(['/customer']);
          } else {
            this.showForm = true;
          }
        }
        if (!this.userService?.captchaValidated && !this.userAccount) {
          this.router.navigate(['/customer']);
        } else {
          this.showForm = true;
        }
      },
    });
    this.projectId = this.activatedRoute.snapshot.paramMap.get('id') || '';
    if (this.projectId === 'null' || !this.projectId) {
      this.projectId = null;
      this.makeSampleEditable = true;
      this.precastService.sampleEditable = true;
    }
    if (this.router.url.includes('create')) {
      this.formService.updateData(null);
      this.projectId = null;
      this.makeSampleEditable = true;
    } else {
      this.commentsValid = true;
      this.setDataForForm();
    }

    const fromWhere = this.activatedRoute.snapshot.queryParamMap.get('f');
    this.fromWhere = this.setWhereViewSubmissionClicked(fromWhere);
  }

  setDataForForm(): void {
    this.formService.getSavedData()?.subscribe((data: IPrecastRecord | null) => {
      this.data = data;
      if (!this.data && this.projectId && this.projectId.length > 10) {
        this.checkLoadForm = false;
        this.formService.fetchFormData(this.projectId, this.submissionType).subscribe({
          next: (fetchedData: IPrecastRecord) => {
            fetchedData.samples?.forEach((elem) => {
              if (this.submissionType === 'precast' && elem.sampleCollectionDate) {
                elem.sampleCollectionDate = this.getActualDate(elem.sampleCollectionDate);
              }
              if (this.submissionType === 'native-soil' && elem.collectionDate) {
                elem.collectionDate = this.getActualDate(elem.collectionDate);
              }
            });
            this.setViewFormData(fetchedData);
          },
          error: () => {},
        });
      } else {
        if (!data) {
          data = JSON.parse(localStorage.getItem('reviewForm') || '{}');
        }
        this.setViewFormData(data);
      }
    });
  }

  getActualDate(date: string | Date): Date {
    const utcDate = new Date(date);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - localTimeZoneOffset * 60000);
    return localDate;
  }

  setViewFormData(data: any): void {
    this.checkLoadForm = false;
    let billing;
    this.savedFormData = data;
    if (data && data.company) {
      if (data && data.billing) {
        billing = {
          bId: data.billing.id ? data.billing.id : null,
          invoice: data.billing.invoice,
          bemails: data.billing.emails,
          bcontactNumber: data.billing.contactNumber,
          bstreetAddress: data.billing.streetAddress,
          bcountry: data.billing.country,
          bstate: data.billing.state,
          bcity: data.billing.city,
          bpostalCode: data.billing.postalCode,
          bContactEmail: data.billing.contactEmail,
          bContactName: data.billing.contactName,
        };
      }
      this.companyValid = true;
      if (this.submissionType === 'precast') {
        this.sampleDataPrecastValid = true;
        this.sampleDataNativeValid = true;
      } else if (this.submissionType === 'native-soil') {
        this.sampleDataPrecastValid = true;
        this.sampleDataNativeValid = true;
      }

      this.companyInformation = {
        ...data.company,
        ...data.project,
        ...billing,
      };
      this.companyInformation.companyId = data.company.id;
      this.companyInformation.projectId = data.project.id;
      this.soilData = data.samples;
      if (this.submissionType === 'precast') {
        this.requirementComment = data.comments;
      } else if (data.comments && data.comments.length !== 0) {
        this.requirementComment = data.comments;
      }

      this.projectId = data.project.id;
      this.getProjectStatus(data.project.projectStatusTypeId);
      const s: string[] = [];
      if (data?.samples) {
        data?.samples.forEach((element: any) => {
          if (element?.sampleType) {
            s.push(element.sampleType);
          }
        });
        if (this.submissionType === 'precast') {
          this.selectedSampleTypes = Array.from(new Set(s));
          this.sampleDataPrecast = data.samples;
        } else if (this.submissionType === 'native-soil') {
          this.sampleDataNative = data.samples;
        }
      }
    }

    this.checkLoadForm = true;
    this.commentStepperCheck = true;
  }

  ngAfterViewChecked(): void {
    if (this.initialStep) {
      const s = this.activatedRoute.snapshot.queryParamMap.get('s');
      this.setStepperIndex(s);
    }
    this.initialStep = false;
    if (this.stepper) {
      this.stepper.selectedIndex = this.stepperIndex;
      if (this.stepper.selected) {
        this.stepper.selected.interacted = false;
      }
    }

    this.cdr?.detectChanges();
  }

  setStepperIndex(s: string | null) {
    switch (s) {
      case '1':
        this.stepperIndex = 0;
        break;
      case '2':
        this.stepperIndex = 1;
        break;
      case '3':
        this.stepperIndex = 2;
        break;
      default:
    }
  }

  public getCompanyData(data: ICompanyData): void {
    this.company = data?.value;
    this.companyValid = data?.valid;
    this.companyDirty = data?.dirty;
  }

  public getNativeData(data: INativeSampleData): void {
    if (this.makeSampleEditable) {
      this.sampleDataNative = data?.value;
    } else {
      this.sampleDataNative.forEach((elem: any, i) => {
        elem.sampleId = data?.value[i]?.sampleId;
        elem.proctorType = data?.value[i]?.proctorType;
        elem.collectionDate = data?.value[i]?.collectionDate;
        elem.sampleDepth = data?.value[i]?.sampleDepth;
        elem.sampleEndDepth = data?.value[i]?.sampleEndDepth;
        elem.stockPile = data?.value[i]?.stockPile;
        elem.soilDescription = data?.value[i]?.soilDescription;
      });
    }
    this.sampleDataNativeValid = data?.valid;
    this.nativeSampleDirty = data?.dirty;
  }

  public getPrecastData(data: IPrecastSampleData): void {
    if (this.makeSampleEditable) {
      this.sampleDataPrecast = data?.value;
    } else {
      this.sampleDataPrecast.forEach((elem: any, i) => {
        elem.sampleId = data?.value[i]?.sampleId;
        elem.sampleCollectionDate = data?.value[i]?.sampleCollectionDate;
        elem.sampleType = data?.value[i].sampleType;
        elem.ticketNumber = data?.value[i].ticketNumber;
        elem.truckNumber = data?.value[i].truckNumber;
      });
    }
    this.selectedSampleTypes = [...new Set(this.sampleDataPrecast?.map((item: any) => item?.sampleType))];
    this.sampleDataPrecastValid = data?.valid;
    this.precastSampleDirty = data?.dirty;
  }

  public getInformationData(data: ICommentData): void {
    this.requirementComment = data?.value;
    this.instructionsValid = data?.valid;
    this.instructionsDirty = data?.dirty;
  }

  public onCancelButtonClick(): void {
    this.getRedirectCondition() ? this.openConfirmationDialog('onCancel') : this.redirectToCustomerPage();
  }

  public redirectUrl(): void {
    this.getRedirectCondition() ? this.openConfirmationDialog('onRedirect') : this.router.navigate(['/dashboard']);
  }

  private openConfirmationDialog(action: string): void {
    this.pageLeaveDialogRef = this.dialog?.open(ConfirmationDialogComponent, {
      panelClass: 'worksheet-dialog',
      data: {
        title: 'Canceling Sample File',
        content: 'Are you sure want to cancel the sample file.',
        cancelText: 'NO, CONTINUE',
      },
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
    this.pageLeaveDialogRef?.afterClosed()?.subscribe((response?: string) => {
      if (response === 'YES') {
        if (action === 'onCancel') {
          this.redirectToCustomerPage();
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
    });
  }

  private redirectToCustomerPage(): void {
    const params = new HttpParams({
      fromObject: {
        f: 'd',
      },
    });
    const navigateSoilType = this.submissionType === 'native-soil' ? 'nativesoil' : this.submissionType;
    this.formService.attachments.next(null);
    if (!this.projectId && !this.userAccount) {
      this.router.navigate(['/customer']);
    } else if (this.userAccount && !this.projectId) {
      this.router.navigate(['/dashboard']);
    } else {
      sessionStorage.removeItem('editViewSubmission');
      const url = `/review/form/${navigateSoilType}/${this.projectId}?${params.toString()}`;
      this.sharedService.routeValue(url);
    }
  }

  createCompanyPayload(): IPrecastRecord {
    let company: IPrecastRecord;
    if (this.company) {
      this.company.contactNumber = this.company.contactNumber.replace(/\D/g, '');
      this.company.bcontactNumber = this.company?.bcontactNumber?.replace(/\D/g, '');
      let cEmail = this.company?.emails?.trim();
      let bEmail: string = this.company?.bemails?.trim() ?? '';
      if (cEmail?.endsWith(',')) {
        cEmail = cEmail.slice(0, -1);
      }

      if (bEmail && bEmail.endsWith(',')) {
        bEmail = bEmail.slice(0, -1);
      }

      if (this.company.contactNumber) {
        this.company.contactNumber = this.company.contactNumber
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', '')
          .replaceAll('-', '');
      }

      if (this.company.bcontactNumber) {
        this.company.bcontactNumber = this.company.bcontactNumber
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', '')
          .replaceAll('-', '');
      }
      company = {
        company: {
          id: this.company?.companyId || null,
          name: this.company?.name,
          contactNumber: this.company?.contactNumber,
          emails: cEmail,
          contactName: this.company?.contactName,
          streetAddress: this.company?.streetAddress,
          countryId: this.company?.countryId && !Number.isNaN(this.company?.countryId) ? this.company?.countryId : null,
          stateId: this.company?.stateId && !Number.isNaN(this.company?.stateId) ? this.company?.stateId : null,
          cityId: this.company?.cityId && !Number.isNaN(this.company?.cityId) ? this.company?.cityId : null,
          postalCode: this.company?.postalCode,
          reportSubmissionDate: new Date().toISOString(),
        },
        billing: {
          id: this.company?.bId ? this.company?.bId : null,
          invoice: this.company?.invoice ? this.company?.invoice : '',
          contactNumber:
             this.company?.bcontactNumber ? this.company?.bcontactNumber : '',
          emails: this.company?.bemails ? bEmail : '',
          streetAddress: this.company?.bstreetAddress ? this.company?.bstreetAddress : '',
          countryId: this.company?.bcountryId && !Number.isNaN(this.company?.bcountryId) ? this.company?.bcountryId : null,
          stateId: this.company?.bstateId && !Number.isNaN(this.company?.bstateId) ? this.company?.bstateId : null,
          cityId: this.company?.bcityId && !Number.isNaN(this.company?.bcityId) ? this.company?.bcityId : null,
          postalCode: this.company?.bpostalCode ? this.company?.bpostalCode : '',
          contactName: this.company?.bContactName ? this.company?.bContactName : '',
          contactEmail: this.company?.bContactEmail ? this.company?.bContactEmail : '',
        },
        project: {
          projectName: this.company.projectName ? this.company.projectName : '',
          projectLocation: this.company?.projectLocation ? this.company?.projectLocation : '',
          projectNumber: this.company?.projectNumber ? this.company?.projectNumber : '',
          jobNumber: this.company?.jobNumber ? this.company?.jobNumber : '',
          rushTesting: this.company?.rushTesting ? this.company?.rushTesting : false,
          preApproval: this.company?.preApproval ? this.company?.preApproval : false,
          instructionsConfirmed: this.company?.instructionsConfirmed ? this.company?.instructionsConfirmed : false,
          geothermField: this.company?.geothermField ? this.company?.geothermField : false,
          projectStatusTypeId: this.company?.projectStatusTypeId,
          inHouseIdPrefix: this.company?.inHouseIdPrefix,
          hasSameBilling: this.company.hasSameBilling,
          // dueDate:this.company?.dueDate,
        },
        samples: [],
      };
    } else {
      company = this.savedFormData;
    }
    if (this.projectId) {
      company.project.id = this.projectId;
    }
    return company;
  }

  createSamplePayload(): INativeSample[] | IPrecastSample[] {
    return this.submissionType === 'precast' ? this.sampleDataPrecast : this.sampleDataNative;
  }

  private getRedirectCondition(): boolean {
    return (
      this.companyDirty
      || this.instructionsDirty
      || this.precastSampleDirty
      || this.nativeSampleDirty
      || this.projectDirty
    );
  }

  callUpdateData(payload: any) {
    this.formService.updateData(payload);
  }

  review(projectID: string | null, soilType: string): void {
    this.instructionsChangedValue = null;
    const navigateSoilType = soilType === 'precast' ? 'precast' : 'nativesoil';
    const payload = this.createCompanyPayload();
    payload.samples = this.createSamplePayload();
    payload.comments = this.requirementComment;
    if (navigateSoilType === 'nativesoil') {
      this.setCompactionEffort(payload);
    }

    if (payload.project) {
      payload.project.additionalCommentsNativeSoil = payload.project.testingInstructions;
    }
    this.callUpdateData(payload);
    const params = {
      f: this.fromWhere,
      e: this.makeSampleEditable ? '1' : '0',
    };
    const navigationExtras: NavigationExtras = {
      queryParams: params,
    };
    if (projectID) {
      this.router.navigate([`/review/form/${navigateSoilType}/${projectID}`], navigationExtras);
    } else {
      this.router.navigate([`/review/form/${navigateSoilType}`], navigationExtras);
    }
  }

  setCompactionEffort(payload: any) {
    payload.samples.forEach((elem: any) => {
      if (typeof elem.compactionEffort === 'string') {
        elem.compactionEffort = elem.compactionEffort.trim();
        if (elem.compactionEffort.endsWith(',')) {
          elem.compactionEffort = elem.compactionEffort.slice(0, -1);
        }
      }
    });
  }

  stepperPrevious(index: number): void {
    this.stepperIndex = index;
    if (index === 1) {
      this.instructionsChangedValue = {
        ...this.instructions,
      };
    }
  }

  stepperNext(index: number): void {
    this.stepperIndex = index;
    if (index === 2) {
      this.commentsValid = true;
      // if (this.instructionsChangedValue) {
      //   this.requirementComment = {
      //     ...this.instructionsChangedValue,
      //   };
      // }
    }
  }

  stepChanged(event: any, stepper: MatStepper) {
    if (stepper.selected) {
      stepper.selected.interacted = false;
    }
  }

  sampleTypeSelected(comments: any): void {
    this.commentStepperCheck = false;
    comments.forEach((comment:ICommentModel) => {
      const item = this.requirementComment.find((c:ICommentModel) => c.sampleTypeMasterId === comment.sampleTypeMasterId);
      comment.projectComment = item ? item.projectComment : '';
      comment.id = item ? item.id : null;
      comment.projectId = item ? item.projectId : null;
    });
    this.requirementComment = this.sortComments(comments);
    setTimeout(() => {
      this.commentStepperCheck = true;
    }, 1);

    // if (!this.instructionsChangedValue) {
    //   this.instructionsChangedValue = {
    //     ...this.instructions,
    //   };
    // }
    // this.setEmptyToUnselectedSampleTypes(event, this.instructionsChangedValue);
  }

  sortComments(comments: any): ICommentModel [] {
    const sortedComments :ICommentModel [] = [];
    this.preCastSampleTypes.forEach((sComment: PrecastSampleType) => {
      const item = comments.find((c:ICommentModel) => c.sampleTypeMasterId === sComment.id);
      if (item) {
        sortedComments.push(item);
      }
    });
    return sortedComments;
  }

  setEmptyToUnselectedSampleTypes(sampleTypes: string[], data: IInstructionModel): void {
    // if (sampleTypes.indexOf('CONCRETE') < 0) {
    //   data.concreteInstruction = '';
    // }
    // if (sampleTypes.indexOf('FTB') < 0) {
    //   data.ftbInstruction = '';
    // }
    // if (sampleTypes.indexOf('GROUT/SLURRY') < 0) {
    //   data.groutSlurryInstruction = '';
    // }
    // if (sampleTypes.indexOf('OTHER') < 0) {
    //   data.otherInstruction = '';
    // }
    // data.additionalCommentsNativeSoil = '';
    // if (data) {
    //   this.requirementComment = data;
    // }
  }

  setWhereViewSubmissionClicked(fromWhere: string | null): string {
    if (fromWhere === 'w') {
      fromWhere = 'w';
    } else {
      fromWhere = 'd';
    }
    return fromWhere;
  }

  setProjectStatusArray(projectStatuses: IDropdown[]): void {
    this.projectStatuses = [];
    projectStatuses.forEach((elem: IDropdown) => {
      switch (elem.label) {
        case 'Samples Due To Arrive':
        case 'Shipment Received':
        case 'Awaiting Instructions':
          this.projectStatuses.push(elem);
          break;
        default:
      }
    });
  }

  checktoEnableEditButton(id: string | undefined): boolean {
    const index = this.projectStatuses.findIndex((x) => x.id === id);
    if (index > -1) {
      return true;
    }
    return false;
  }

  getProjectStatus(id: string): void {
    if (this.projectId) {
      if (this.projectStatuses && this.projectStatuses.length === 0) {
        this.precastService
          .fetchProjectStatuses()
          .pipe(take(1))
          ?.subscribe({
            next: (projectStatuses: IDropdown[]) => {
              this.setProjectStatusArray(projectStatuses);
              this.makeSampleEditable = this.checktoEnableEditButton(id);
              this.precastService.sampleEditable = this.makeSampleEditable;
              this.checkLoadForm = true;
            },
          });
      } else {
        this.makeSampleEditable = this.checktoEnableEditButton(id);
        this.checkLoadForm = true;
      }
    }
  }

  getSampleTypeLookUp(): void {
    this.lookupService.getPrecastSampletypes()?.subscribe({
      next: (response: PrecastSampleType []) => {
        this.preCastSampleTypes = response;
      },
    });
  }

  getNativeSampleTypes(): void {
    this.precastService.fetchNativeSampleTypes()?.subscribe({
      next: (response: NativeSampleType []) => {
        this.nativeSampleType = response;
        if (this.submissionType === 'native-soil') {
          this.requirementComment = [{
            id: null,
            projectId: null,
            sampleTypeMasterId: this.nativeSampleType[0].id,
            projectComment: '',
          }];
          setTimeout(() => {
            this.commentStepperCheck = true;
          }, 5);
        }
      },
    });
  }
}
