import { IMessage } from '../models/message.modal';

export const messagesConfig = {
  formSubmitSuccessMessage: <IMessage>{
    title: 'Submitted Successfully',
    message:
      'Thank you for your submission.<br>Your list of samples has been submitted successfully.'
      + '<br>We will contact you if we have any questions.<br>We shall notify you once we receive your samples.',
    type: 'SUCCESS',
  },
  formSubmitUserSuccessMessage: <IMessage>{
    title: 'Submitted Successfully',
    message: 'Your record has been saved successfully.',
    type: 'SUCCESS',
  },
  formSubmitErrorMessage: <IMessage>{
    title: 'Error Submitted Form',
    message:
      'There is a technical error while submitting your form. Please try again.<br>If this issue persists, please contact us for more information.',
    type: 'WARNING',
  },
  generalError: <IMessage>{
    title: 'Error Submitted Form',
    message:
      'There is a technical error while submitting your form. Please try again.<br>If this issue persists, please contact us for more information.',
    type: 'WARNING',
  },
  formSubmitValidationMessage: <IMessage>{
    title: 'Invalid or missing information',
    message: 'Please correct the issues noted on the form before moving the next step.',
    type: 'WARNING',
  },
  maxSampleWarning: <IMessage>{
    title: 'Warning',
    message: 'Number of Samples should not exceed more than 50.',
    type: 'WARNING',
  },
  notesSubmitSuccessMessage: <IMessage>{
    title: 'Success',
    message: 'Note has been Added Successfully.',
    type: 'SUCCESS',
  },
  generalSubmitSuccessMessage: <IMessage>{
    title: 'Success',
    message: 'Record has been updated successfully.',
    type: 'SUCCESS',
  },
  setStatusMessage: <IMessage>{
    title: 'Success',
    message: 'Status has been successfully set.',
    type: 'SUCCESS',
  },
  assignTolabTech: <IMessage>{
    title: 'Success',
    message: 'Worksheet(s) has been assigned successfully.',
    type: 'SUCCESS',
  },
  worksheetDeleteValidation: <IMessage>{
    title: 'Warning',
    message: 'Worksheet row cannot be deleted. Atleast one woksheet row is required.',
    type: 'WARNING',
  },
  worksheetDeleteMessage: <IMessage>{
    title: 'Success',
    message: 'Worksheet row has been deleted successfully.',
    type: 'SUCCESS',
  },
  internalServerError: <IMessage>{
    title: 'Internal Server Error',
    message: 'Internal Server Error Occured. Please try again later or contact Administrator',
    type: 'WARNING',
  },
  serviceNotFound: <IMessage>{
    title: 'Resource Not Found',
    message: 'Resource Not Found. Please try again later or contact Administrator',
    type: 'WARNING',
  },
  unauthorised: <IMessage>{
    title: 'Unauthorised',
    message: 'You are not authorised to access some features. Please try again later or contact Administrator',
    type: 'WARNING',
  },
  serviceUnavailable: <IMessage>{
    title: 'Service Unavailable',
    message: 'Survice Currenlty Unavailable. Please try again later or contact Administrator',
    type: 'WARNING',
  },
  badRequest: <IMessage>{
    title: 'Bad Request',
    message: 'Bad Request Sent. Please check and try again',
    type: 'WARNING',
  },
  customerAttachmentError: <IMessage>{
    title: 'Error Submitted Form',
    message:
      'Sorry, your upload failed due to some criteria not in accordance with instructions.'
      + '<br>You can always send the files that could not be uploaded to '
      + '<a href="mailto:samples@geothermusa.com" style="color: white !important;">samples@geothermusa.com</a>',
    type: 'WARNING',
  },
  changeSuccessMeaage: <IMessage>{
    title: 'Success',
    message: 'Your changes have been saved successfully.',
    type: 'SUCCESS',
  },
  apiUploadSuccessMessage: <IMessage>{
    title: 'Success',
    message: 'File uploaded have been saved successfully.',
    type: 'SUCCESS',
  },

  uploadApiFailed: <IMessage>{
    title: 'Request Failed',
    message: 'File could not be uploaded. Please check and try again.',
    type: 'WARNING',
  },

  softDeleteMessage: <IMessage>{
    title: 'Success',
    message: 'Record has been deleted successfully.',
    type: 'SUCCESS',
  },

  viewForm: <IMessage>{
    title: 'Request Failed',
    message: 'Please check and complete Shipment Received details, before viewing the Submission form.',
    type: 'WARNING',
  },

  emailSendSuccessfully: <IMessage>{
    title: 'Message Sent',
    message: 'An email with the information provided has been sent to the customer.',
    type: 'SUCCESS',
  },

  restoreDeleteMessage: <IMessage>{
    title: 'Success',
    message: 'Record has been restored successfully.',
    type: 'SUCCESS',
  },

  inActivateRecordMessage: <IMessage>{
    title: 'Success',
    message: 'Record has been inactivated successfully.',
    type: 'SUCCESS',
  },

  fileuploadLimit: <IMessage>{
    title: 'Success',
    message: 'File size exceeds the 10MB limit. Please select smaller files.',
    type: 'WARNING',
  },

  fileuploadDuplicate: <IMessage>{
    title: 'Success',
    message: 'Please select only unique files.',
    type: 'WARNING',
  },

  fileNameListEmpty: <IMessage>{
    title: 'Success',
    message: 'No Records Found.',
    type: 'WARNING',
  },

  dueDateUpdated: <IMessage>{
    title: 'Success',
    message: 'Due Date is updated successfully.',
    type: 'SUCCESS',
  },

  inHouseIDUpdated: <IMessage>{
    title: 'Success',
    message: 'InHouse Id updated.',
    type: 'SUCCESS',
  },

  deleteFileMessage: <IMessage>{
    title: 'Success',
    message: 'File has been deleted successfully.',
    type: 'SUCCESS',
  },

  errorSubmissionMessage: <IMessage>{
    title: 'Error Submitted Form',
    message: 'The weight entered does not match the expected weight.',
    type: 'WARNING',
  },

};
