import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class GeoMapService {
  constructor(private httpSerivce: HttpService) {}

  getLatAndLong(): Observable<any> {
    return this.httpSerivce.get('map/getcountries');
  }

  getCityPredictionData(city: any): Observable<any> {
    return this.httpSerivce.post('map/postlatlongradius', city);
  }

  getPrecastLatLong(): Observable<any> {
    return this.httpSerivce.get('map/getprecastcountries');
  }

  getPrecastCityPredictionData(city: any): Observable<any> {
    return this.httpSerivce.post('map/postprecastlatlongradius', city);
  }

  getPostNativeFilename(city: any): Observable<any> {
    return this.httpSerivce.post('map/postnativelatlogfilename', city);
  }

  getPostPrecastFilename(city: any): Observable<any> {
    return this.httpSerivce.post('map/postprecastlatlogfilename', city);
  }
}
