import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appThreeDigitOneDecimal]'
})
export class ThreeDigitOneDecimalDirective {

  private regex: RegExp = new RegExp(/^\d{0,3}(\.\d{0,1})?$/); // Allows up to 3 digits before the decimal and 1 digit after
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor() {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
//    console.log('Key pressed:', event.key); // Log key presses for debugging

    if (this.specialKeys.includes(event.key)) {
  //    console.log('Special key allowed');
      return; // Allow special keys
    }

    const inputElement = event.target as HTMLInputElement;
    const current: string = inputElement.value;
    const next: string = current.concat(event.key);

  //  console.log('Current value:', current);
  //  console.log('Next value (after key press):', next);

    // Prevent input if it doesn't match the regex
    if (!this.regex.test(next)) {
   //   console.log('Input prevented - does not match regex');
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const pastedInput = clipboardData?.getData('text') ?? '';

   // console.log('Pasted input:', pastedInput);

    if (!this.regex.test(pastedInput)) {
    //  console.log('Paste prevented - does not match regex');
      event.preventDefault();
    }
  }
}
