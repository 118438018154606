<div class="section-1200">
  <p class="heading">Material/Sample Information</p>
  <form [formGroup]="sampleDataPrecastForm" [ngClass]="{ 'disabled-wrapper': !makeSampleEditable }">
    <div formArrayName="samples">
      <ng-container *ngFor="let sample of getSampleControls.controls; let i = index">
        <mat-card [formGroupName]="i" class="margin-bottom-20">
          <mat-card-content class="sample-info-card">
            <div class="row">
              <div class="col-sm-11">
                <div class="row">
                  <div class="col-md-3" [ngClass]="{ 'pre-approval-space': companyData?.preApproval }">
                    <mat-label class="mat-label"> Sample ID<span class="required">*</span> </mat-label>
                    <mat-form-field appearance="outline">
                      <input matInput formControlName="sampleId" />
                      <mat-hint *ngIf="companyData?.preApproval" class="mat-hint-red"
                        >Please enter your Mix Design code in Sample ID field.
                      </mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="col-md-3">
                    <mat-label class="mat-label">
                      Sample Date<span class="required">*</span>
                      <a
                        href="javascript:void(0)"
                        #tooltip="matTooltip"
                        alt="tooltip-information"
                        matTooltip="Date of sample cast and not sample collection">
                        <img src="/assets/icons/Alert-grey.svg" alt="alert-icon" />
                      </a>
                    </mat-label>
                    <mat-form-field appearance="outline" class="mat-form-field-width">
                      <input
                        formControlName="sampleCollectionDate"
                        [max]="currentDate"
                        class="mat-date-input"
                        readonly
                        (dateChange)="datechange($event.value, i)"
                        matInput
                        [matDatepicker]="picker" />
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 online-type">
                    <mat-label class="mat-label"> Sample Type<span class="required">*</span> </mat-label>
                    <mat-form-field appearance="outline" class="mat-form-field-width">
                      <mat-select
                        (selectionChange)="sampleTypeChange()"
                        formControlName="sampleTypeMasterId"
                        disableOptionCentering
                        placeholder="Select">
                        <mat-option *ngFor="let sampletype of sampleTypes" [value]="sampletype.id" class="arrow">
                          {{ sampletype.label }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-label class="mat-label"> Samples Per Set<span class="required">*</span> </mat-label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        required
                        onkeydown="return !(event.keyCode == 69)"
                        type="number"
                        min="1"
                        max="3"
                        [length]="1"
                        appNumberInput
                        formControlName="numSamplesPerSet"/>
                    </mat-form-field>
                    <mat-error
                      class="mat-error-nativesoil"
                      *ngIf="
                        getSampleControls.at(i).get('numSamplesPerSet')?.invalid &&
                        (getSampleControls.at(i).get('numSamplesPerSet')?.touched ||
                          getSampleControls.at(i).get('numSamplesPerSet')?.dirty)
                      "
                      style="margin: -26px 0px 0px 0px">
                      <span>{{ getErrorMessage(i) }}</span>
                    </mat-error>
                  </div>
                  <div class="col-md-3">
                    <mat-label class="mat-label">Mix Design Code</mat-label>
                    <mat-form-field appearance="outline" class="mat-form-field-width">
                      <input matInput formControlName="mixDesignCode" maxlength="50" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-label class="mat-label"> Truck # </mat-label>
                    <mat-form-field appearance="outline" class="mat-form-field-width">
                      <input matInput formControlName="truckNumber" [maxLength]="10" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-label class="mat-label"> Ticket # </mat-label>
                    <mat-form-field appearance="outline" class="mat-form-field-width">
                      <input matInput formControlName="ticketNumber" [maxLength]="10" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-1" *ngIf="getSampleControls.controls.length > 1">
                <mat-icon
                  *ngIf="makeSampleEditable"
                  aria-hidden="false"
                  (click)="removeItem(i)"
                  class="remove-icon cursor"
                  aria-label="Remove Sample icon"></mat-icon>
              </div>
            </div>
            <mat-error *ngIf="sampleDataPrecastForm.value.samples[i].isUnique"
              >Sample ID, Ticket # or Truck # already exists. Please enter a new valid input.</mat-error
            >
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>
  </form>
  <mat-card class="margin-bottom-20">
    <mat-card-content>
      <a
        [ngClass]="{ 'sample-disabled': !makeSampleEditable }"
        class="add-sample"
        href="javascript:void(0);"
        (click)="toggleButtonState()">
        <mat-icon class="add-icon"></mat-icon>
        <span class="alink"> Add Sample </span>
      </a>
    </mat-card-content>
  </mat-card>
</div>
