<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="clearfix">
  <div class="float-left">
    <p class="heading">Material/Sample Information</p>
  </div>
  <div class="float-right test-mar-top">
    <mat-icon aria-hidden="false" class="info-icon" aria-label="Example info icon"></mat-icon>
    <a
      href="javascript:void(0)"
      class="testing"
      (click)="openInstructionsDialog()"
      (keypress)="openInstructionsDialog()">
      Testing Instructions
    </a>
  </div>
</div>
<form [formGroup]="sampleDataNativeSoilForm">
  <div formArrayName="samples" class="s-wrapper" [ngClass]="{ 'disabled-wrapper': !makeSampleEditable }">
    <ng-container *ngFor="let sample of getSampleControls.controls; let i = index">
      <mat-accordion class="exp-bg example-headers-align native-soil-panel" multi (click)="$event.stopPropagation()">
        <mat-expansion-panel
          (click)="$event.stopPropagation()"
          id="mapanel_{{ i }}"
          [formGroupName]="i"
          [expanded]="step === i"
          (opened)="setStep(i)">
          <mat-expansion-panel-header (click)="handlePanelClick($event)" style="cursor: default">
            <div class="row wraper-padi" (click)="$event.stopPropagation()">
              <div class="col-md-2"> 
                <mat-label class="mat-label">Sample Type<span class="required">*</span> </mat-label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="sampleTypeMasterId"
                    placeholder="Choose"
                    disableOptionCentering
                    required
                    (click)="$event.stopPropagation()"
                    appPreventSpacebarPropagation
                    (selectionChange)="onNativeSoilTypeChange(sample)">
                    <mat-option *ngFor="let sampletype of nativeSampleType" [value]="sampletype.id">
                      {{ sampletype.label }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div class="default-error">
                  <span>Please note, Geotherm does not accept contaminated samples.</span>
                </div>
              </div>
              <div class="col-md-3 sample-id">
                <mat-label class="mat-label">Sample ID<span class="required">*</span></mat-label>
                <mat-form-field appearance="outline">
                  <input appPreventSpacebarPropagation matInput formControlName="sampleId" type="text" />
                </mat-form-field>
              </div>
              <div class="col-md-1 stockpile">
                <mat-checkbox
                  color="primary"
                  class="use-compaction"
                  formControlName="stockPile"
                  style="margin-left: -12px"
                  (click)="onStockPileCheckBoxClicked(i)">
                  <span class="checkbox-text-use">Stockpile </span>
                </mat-checkbox>
              </div>
              <div class="col-md-3 depth-value">
                <div class="depth-wrapper">
                  <div class="l">
                    <mat-label class="mat-label">
                      Start Depth (ft)
                      <!-- <span
                        *ngIf="
                          getSampleControls.controls[i].get('sampleDepth')?.value !== '' &&
                          getSampleControls.controls[i].get('sampleDepth')?.value !== null
                        "
                        class="required">
                        *
                      </span> -->
                    </mat-label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        formControlName="sampleDepth"
                        appNegativeDecimal
                        (input)="onInputChange($event)"
                       />
                    </mat-form-field>
                  </div>
                  <span class="sp"></span>
                  <div class="r">
                    <mat-label class="mat-label">
                      End Depth (ft)
                      <!-- <span
                        *ngIf="
                          getSampleControls.controls[i].get('sampleDepth')?.value !== '' &&
                          getSampleControls.controls[i].get('sampleDepth')?.value !== null
                        "
                        class="required">
                        *
                      </span> -->
                    </mat-label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        formControlName="sampleEndDepth"
                        appNegativeDecimal
                        (input)="onInputChange($event)"
                        (keyup) = "depthEndValidation(i, 'sampleDepth', 'sampleEndDepth')" />
                    </mat-form-field>
                  </div>                
                  <div class="clearfix"></div>                  
                </div>
                <mat-error class="font-size-14">
                  <span class="err">{{ activeDepthError(sample) }}</span>
                </mat-error>
              </div>

              <div class="col-md-2 collection-date">
                <mat-label class="mat-label">Collection Date</mat-label>
                <mat-form-field appearance="outline">
                  <input
                    formControlName="collectionDate"
                    [max]="currentDate"
                    class="mat-date-input"
                    readonly
                    appPreventSpacebarPropagation
                    (dateChange)="datechange($event.value, i)"
                    matInput
                    [matDatepicker]="picker" />
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-1" *ngIf="i > 0 || getSampleControls.length > 1">
                <mat-icon
                  *ngIf="makeSampleEditable"
                  aria-hidden="false"
                  class="remove-icon remove-icon-native cursor"
                  ria-label="Remove Sample icon"
                  (click)="removeItem(i)"
                  appPreventSpacebarPropagation
                  (keypress)="$event.stopPropagation()"></mat-icon>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="row">
            <div class="col-md-12 online-type">
              <mat-label class="mat-label form-label">Soil/Backfill Description</mat-label>
              <mat-form-field appearance="outline" class="mat-form-field-width soil-note">
                <input matInput formControlName="soilDescription" type="text" id="companyName" />
              </mat-form-field>
            </div>
          </div>
          <mat-card
            class="soil-card native-bulk-card"
            *ngIf="checksoilType(i) === '3ab1b1ca-7964-4a72-9818-202f0b3c592b'">
            <mat-card-content>
              <div class="mb-2 bulk-header">Bulk Testing Requirements</div>
              <div class="bulk-note">Note: The following fields are required prior to testing</div>
              <div class="mb-2 bulk-sub-header">Proctor Results</div>
              <div class="row bulk-section">
                <div class="col-md-3 remove-before">
                  <mat-label class="mat-label"
                    >Max Dry Density (lb/ft3)
                    <img
                      alt="info Icon"
                      class="star-mar-top"
                      #tooltip="matTooltip"
                      matTooltip="Max. 4 digits allowed, including one place of decimal. e.g. XXX.X"
                      src="/assets/icons/Alert-grey.svg" />
                  </mat-label>
                  <mat-form-field appearance="outline">
                    <input matInput formControlName="maxDryDensity" [length]="5" appRemoveLeadingZero appDecimalInput />
                  </mat-form-field>
                  <mat-error class="font-size-14">
                    <span class="err">{{ maxDryDensityError(sample) }}</span>
                  </mat-error>
                </div>
                <div class="col-md-2 remove-before">
                  <mat-label class="mat-label"
                    >Optimum M/C (%)
                    <img
                      alt="info Icon"
                      class="star-mar-top"
                      #tooltip="matTooltip"
                      matTooltip="Max. 4 digits allowed, including one place of decimal. e.g. XXX.X"
                      src="/assets/icons/Alert-grey.svg" />
                  </mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="optimumMoistureContent"
                      [length]="5"
                      appRemoveLeadingZero
                      appDecimalInput />
                  </mat-form-field>
                  <mat-error class="font-size-14">
                    <span class="err">{{ optimumMoistureContentError(sample) }}</span>
                  </mat-error>
                </div>
                <div class="col-md-4">
                  <mat-label class="mat-label"
                    >Compaction Effort(s) % for testing
                    <span class="required">*</span>
                    <a
                      href="javascript:void(0)"
                      #tooltip="matTooltip"
                      alt="tooltip-information"
                      matTooltip="Please separate values with a comma “,” ">
                      <img src="/assets/icons/Alert-grey.svg" alt="alert-icon" />
                    </a>
                  </mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      onkeydown="return !(event.keyCode == 69)"
                      type="text"
                      maxlength="30"
                      formControlName="compactionEffort"
                      appCommaNumber />
                  </mat-form-field>
                </div>
                <div class="col-md-5">
                  <mat-checkbox
                    color="primary"
                    class="use-compaction"
                    formControlName="applyCompactionEffort"
                    (click)="onChangeUseCompactionCheckBox(i)"
                    [attr.disabled]="selectedIndex > -1 && i !== selectedIndex">
                    <span class="checkbox-text-use">Use this Compaction for all samples </span>
                  </mat-checkbox>
                </div>
              </div>
              <div>
                <div class="bulk-sub-header">Moisture Conditioning Requirements</div>
                <div class="mt-3 bulk-sub-required">
                  Proctor Type <span class="required">*</span>
                  <a
                    href="javascript:void(0)"
                    #tooltip="matTooltip"
                    alt="tooltip-information"
                    matTooltip="Please select applicable Proctor test">
                    <img src="/assets/icons/Alert-grey.svg" alt="alert-icon" />
                  </a>
                </div>
                <div>
                  <mat-radio-group aria-label="Select an option" color="primary" formControlName="proctorType">
                    <mat-radio-button [value]="true" style="margin-right: 20px">Standard (ASTM D698)</mat-radio-button>
                    <mat-radio-button [value]="false">Modified (ASTM D1557)</mat-radio-button>
                  </mat-radio-group>
                  <mat-error class="error-mar-left" *ngIf="checkProctorTypeIsValid(sample)">
                    {{ checkProctorTypeIsValid(sample) }}
                  </mat-error>
                </div>
              </div>
              <span>
                <div class="mt-3 bulk-sub-required">
                  Moisture Content <span class="required">*</span>
                  <a
                    href="javascript:void(0)"
                    #tooltip="matTooltip"
                    alt="tooltip-information"
                    matTooltip="Please select applicable moisture content">
                    <img src="/assets/icons/Alert-grey.svg" alt="alert-icon" />
                  </a>
                </div>
                <div class="mc-radio-wrapper">
                  <mat-radio-group
                    aria-label="Select an option"
                    (change)="onNativeSoilSampleTypeChange(sample)"
                    color="primary"
                    formControlName="highestMoistureContent">
                    <div class="row">
                      <div class="col-md-2">
                        <mat-radio-button value="1">Optimum</mat-radio-button>
                      </div>
                      <div class="col-md-3">
                        <mat-radio-button value="2" class="radio-width"> In-Situ/As Received </mat-radio-button>
                      </div>
                      <div class="col-md-7">
                        <mat-radio-button value="3" class="radio-width">
                          Specified (%)
                          <img
                            alt="info Icon"
                            class="star-mar-top mrg-right"
                            #tooltip="matTooltip"
                            matTooltip="Max. 4 digits allowed, including one place of decimal. e.g. XXX.X"
                            src="/assets/icons/Alert-grey.svg" />
                          <input
                            matInput
                            [length]="5"
                            class="field-worksheet-outline"
                            formControlName="specifiedMoistureContent"
                            appRemoveLeadingZero
                            appDecimalInput />
                        </mat-radio-button>
                        <mat-error class="font-size-14 specified-error-text">
                          <span class="err">{{ specifiedMoistureContentError(sample) }}</span>
                        </mat-error>
                      </div>
                    </div>
                    <mat-error class="error-mar-left" *ngIf="checkhighestMoistureContentIsValid(sample)">
                      {{ checkhighestMoistureContentIsValid(sample) }}
                    </mat-error>
                  </mat-radio-group>
                </div>
              </span>
              <div class="add-note">Note: To submit more than one type, please add an additional sample below</div>
            </mat-card-content>
          </mat-card>
          <br />
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </div>
</form>
<mat-card class="margin-bottom-20">
  <mat-card-content>
    <a
      [ngClass]="{ 'sample-disabled': !makeSampleEditable }"
      href="javascript:void(0);"
      (click)="addSample()"
      class="add-sample"
      ><mat-icon class="add-icon"></mat-icon>
      <span class="alink"> Add Sample </span>
    </a>
  </mat-card-content>
</mat-card>
