import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PageLoaderService } from '../components/page-loader/page-loader.service';

@Injectable()
export class PageLoaderInterceptor implements HttpInterceptor {
  constructor(private PageloaderService: PageLoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    setTimeout(() => {
      this.PageloaderService.addLoaderCount();
    });
    return next.handle(request).pipe(finalize(() => this.PageloaderService.reduceLoaderCount()));
  }
}
