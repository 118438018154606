import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Quill from 'quill';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-richtext-editor',
  templateUrl: './richtext-editor.component.html',
  styleUrls: ['./richtext-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichtextEditorComponent),
      multi: true,
    },
  ],
})
export class RichtextEditorComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  editor: any;
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  private initialContent = '';
  contentChangeSubject = new Subject<string>();

  @Input() minHeight = '300px';
  @Output() contentChanged = new EventEmitter<string>();

  @Input() set content(value: string) {
    if (this.initialContent !== value) {
      this.initialContent = value;
      if (this.editor && this.editor.root.innerHTML !== value) {
        const range = this.editor.getSelection();
        this.editor.root.innerHTML = value;
        if (range) {
          this.editor.setSelection(range.index, range.length);
        }
      }
    }
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.contentChangeSubject.pipe(debounceTime(300)).subscribe((content) => {
      this.contentChanged.emit(content);
      this.onChange(content); // Notify Angular Form of content change
    });
  }

  ngAfterViewInit() {
    const container = this.elementRef.nativeElement.querySelector('#editor-container');
    if (container) {
      container.style.minHeight = this.minHeight;
      this.editor = new Quill(container, {
        theme: 'snow',
        readOnly: false,
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }],
            ['link', 'image'],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
          ],
        },
      });
      this.editor.root.innerHTML = this.initialContent;

      this.editor.on('text-change', () => {
        const content = this.editor.root.innerHTML;
        if (content === '<p><br></p>' || content.trim() === '<p><br></p>') {
          this.contentChangeSubject.next(''); // Send empty string
        } else {
          this.contentChangeSubject.next(content);
        }
      });
    } else {
      console.error('Editor container not found');
    }
  }

  ngOnDestroy() {
    if (this.editor) {
      this.editor.disable();
    }
    this.contentChangeSubject.unsubscribe();
  }

  // ControlValueAccessor Implementation
  writeValue(value: string): void {
   // console.log('writeValue called with:', value);
    // if (this.editor && this.editor.root.innerHTML !== value) {
    //   this.editor.root.innerHTML = value || '';
    // }
   /* if (this.editor) {
      const currentRange = this.editor.getSelection(); // Get current cursor position
      this.editor.clipboard.dangerouslyPasteHTML(value || ''); // Update content safely
      if (currentRange) {
        this.editor.setSelection(currentRange.index, currentRange.length); // Restore cursor position
      }
    } else {
      this.initialContent = value || ''; // Fallback for uninitialized editor
    }*/
      if (this.editor) {
        if (this.editor.root.innerHTML !== value) {
          const scrollTop = this.editor.root.scrollTop;
          this.editor.clipboard.dangerouslyPasteHTML(value || '');
          this.editor.root.scrollTop = scrollTop;
        }
      } else {
        this.initialContent = value || '';
      }
    // if (this.editor) {
    //   this.editor.root.innerHTML = value || '';
    // } else {
    //   this.initialContent = value || '';
    // }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.editor) {
      this.editor.enable(!isDisabled);
    }
  }
}
