import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { IState } from '../models/i-state.modal';
import { ICities } from '../models/i-cities.modal';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  constructor(private httpService: HttpService) {}
  public fetchCountries() {
    const url = 'lookup/getallcountries';
    return this.httpService.get(url);
  }

  public fetchState(countryId: string): Observable<IState> {
    const url = `lookup/getallstatesbycountryid/${countryId}`;
    return this.httpService.get(url);
  }

  public fetchCities(stateId: string): Observable<ICities> {
    const url = `lookup/getallcitiesbystateid/${stateId}`;
    return this.httpService.get(url);
  }

  public getPrecastSampletypes() {
    const url = 'lookup/getprecastsampletypes';
    return this.httpService.get(url);
  }
}
