<!-- <form [formGroup]="requirementCommentForm">
  <div *ngIf="submissionType === 'precast'">
    <p class="heading">Thermal Requirements</p>
    <div class="row">
      <div class="col-md 12" *ngIf="selectedSampleTypes && selectedSampleTypes.indexOf('CONCRETE') >= 0">
        <mat-card-subtitle class="subtitle-instructions"> Concrete </mat-card-subtitle>
        <textarea
          matInput
          class="instructions-card"
          formControlName="concreteInstruction"
          placeholder="Please enter thermal requirement details about the selected samples here. Example: No higher than 50 C-cm/W at critical moisture content and 100 C-cm/W at zero (0%) moisture content"></textarea>
      </div>
     
      <div *ngIf="selectedSampleTypes && selectedSampleTypes.indexOf('FTB') >= 0">
        <mat-card-subtitle class="subtitle-instructions"> FTB  </mat-card-subtitle>
        <textarea
          matInput
          class="instructions-card"
          placeholder="Please enter thermal requirement details about the selected samples here. Example: No higher than 50 C-cm/W at critical moisture content and 100 C-cm/W at zero (0%) moisture content"
          formControlName="ftbInstruction"></textarea>
      </div>

      <div *ngIf="selectedSampleTypes && selectedSampleTypes.indexOf('GROUT/SLURRY') >= 0">
        <mat-card-subtitle class="subtitle-instructions"> Grout/Slurry  </mat-card-subtitle>
        <textarea
          matInput
          class="instructions-card"
          placeholder="Please enter thermal requirement details about the selected samples here. Example: No higher than 50 C-cm/W at critical moisture content and 100 C-cm/W at zero (0%) moisture content"
          formControlName="groutSlurryInstruction"></textarea>
      </div>
     
      <div *ngIf="selectedSampleTypes && selectedSampleTypes.indexOf('OTHER') >= 0">
        <mat-card-subtitle class="subtitle-instructions"> Others </mat-card-subtitle>
        <textarea
          matInput
          class="instructions-card"
          placeholder="Please enter thermal requirement details about the selected samples here. Example: No higher than 50 C-cm/W at critical moisture content and 100 C-cm/W at zero (0%) moisture content"
          formControlName="otherInstruction"></textarea>
      </div>
    </div>
  </div>
  <div *ngIf="submissionType === 'native-soil'">
    <p class="heading">Additional Comments</p>
    <div class="row">
      <div class="col-md 12">
        <mat-card-subtitle class="subtitle-instructions"> Comments </mat-card-subtitle>
        <textarea
          matInput
          class="instructions-card"
          placeholder="Please supply any additional comments on thermal requirements here"
          formControlName="additionalCommentsNativeSoil"></textarea>
      </div>
    </div>
  </div>
</form> -->





<form [formGroup]="requirementCommentForm" *ngIf="data && data.length > 0">
  <div *ngIf="submissionType === 'precast'">
    <p class="heading">Thermal Requirements</p>
    <div class="row">
      <div class="col-md 12">     
          <div formArrayName="comments" *ngFor="let item of comments.controls; let i = index">
            <div [formGroupName]="i" class="form-group">
              <mat-card-subtitle class="subtitle-instructions">
                 {{ getSampleTypeName(item.value.sampleTypeMasterId) }}
              </mat-card-subtitle>
              <textarea
              matInput
              class="instructions-card"
              formControlName = "projectComment"
              placeholder="Please enter thermal requirement details about the selected samples here. Example: No higher than 50 C-cm/W at critical moisture content and 100 C-cm/W at zero (0%) moisture content"></textarea>
            </div>
          </div>
      </div>     
    </div>
  </div>
  <div *ngIf="submissionType === 'native-soil'">
    <p class="heading">Additional Comments</p>
    <div class="row">
      <div class="col-md 12">
        <div formArrayName="comments" *ngFor="let item of comments.controls; let i = index">
          <div [formGroupName]="i" class="form-group">
            <mat-card-subtitle class="subtitle-instructions"> Comments </mat-card-subtitle>
            <textarea
              matInput
              class="instructions-card"
              placeholder="Please supply any additional comments on thermal requirements here"
              formControlName="projectComment"></textarea>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</form>



