import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgChartsModule } from 'ng2-charts';
import { AzureAdB2C } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminModule } from './features/admin/admin.module';
import { FeaturesModule } from './features/features.module';
import { PageLoaderComponent } from './shared/components/page-loader/page-loader.component';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { PageLoaderInterceptor } from './shared/interceptors/page-loader.interceptor';
import { GlobalErrorHandler } from './shared/services/global-error-handler.service';
import { SharedModule } from './shared/shared.module';
import { MatRadioModule } from '@angular/material/radio';

const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [AppComponent, PageLoaderComponent],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    FeaturesModule,
    SharedModule,
    AdminModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: AzureAdB2C.clientId,
          redirectUri: AzureAdB2C.redirectUri,
          authority: AzureAdB2C.authorty,
        },
        cache: {
          cacheLocation: AzureAdB2C.cacheLocation,
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: AzureAdB2C.scope,
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: AzureAdB2C.protectedResourceMap,
      },
    ),
    NgChartsModule,
  ],

  providers: [
    {
      provide: ErrorHandler, useClass: GlobalErrorHandler,
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: 'UTC',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PageLoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    MsalGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
