<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="native-soil-wrapper" *ngIf="showTable">
  <div class="n-tab-container">
    <div class="n-tab" [ngClass]="{ 'tube-w': !isBulk }">
      <mat-tab-group animationDuration="0" [selectedIndex]="activeTabIndex" (selectedTabChange)="onTabChanged($event)">
        <mat-tab *ngIf="isBulk" label="As Received M/C"></mat-tab>
        <mat-tab *ngIf="isBulk" label="Moisture Conditioning Sheet"></mat-tab>
        <mat-tab label="Worksheet"></mat-tab>
        <mat-tab *ngIf="proctorWorksheets.length > 0" label="Proctor Worksheet"></mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <section class="mt-3">
    <div class="native-table-container">
      <mat-table
        #table
        [dataSource]="dataSource"
        id="mat-t"
        class="mat-elevation-z8"
        aria-describedby="listOfWorksheets"
        *ngIf="dataSource">
        <mat-header-row *matHeaderRowDef="displayedColumns.concat('action'); sticky: true"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns.concat('action')"
          [ngClass]="{ 'gray-zebra': row.sequence % 2 !== 0 }"></mat-row>
        <ng-container *ngFor="let columnName of displayedColumns" [matColumnDef]="columnName">
          <mat-header-cell
            class="header-text"
            *matHeaderCellDef
            [matTooltip]="getTooltipText(columnName)"
            [innerHTML]="getDisplayName(columnName)">
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="row-cell">
            <ng-container *ngIf="columnName === 'sampleId'">
              <div class="sampleid">
                <span class="col-width-100 sp" [matTooltip]="row.sampleId" matTooltipClass="custom-tooltip">
                  {{ row.sampleId | truncate : 57 }}
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="columnName === 'clientDescription'">
              <div class="sampleid">
                <span class="col-width-100 sp" [attr.title]="row.clientDescription" matTooltipClass="custom-tooltip">
                  {{ row.clientDescription | truncate : 57 }}
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="columnName === 'reUse'">
              <img alt="reuse" *ngIf="row.reUse" src="../../../../../assets/icons/Refresh.svg" />
            </ng-container>
            <ng-container *ngIf="columnName === 'status'">
              <img alt="check" *ngIf="row.status" src="../../../../assets/icons/Check.svg" />
              <img alt="icon" *ngIf="!row.status" src="../../../../assets/icons/table icon.svg" />
            </ng-container>
            <!-- Display default content for other columns -->
            <span
              *ngIf="
                columnName !== 'status' &&
                columnName !== 'sampleId' &&
                columnName !== 'clientDescription' &&
                columnName !== 'reUse'
              ">
              {{ getRowDataByKey(row, columnName) }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action" stickyEnd>
          <mat-header-cell class="header-text" *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button
              *ngIf="
                this.userService.roleCheck(['Manager', 'Labtech', 'Admin']) &&
                !(row.worksheetType === 'Tube' && !row.parentRow)
              "
              mat-icon-button
              [matMenuTriggerFor]="menu">
              <mat-icon>more_vert </mat-icon>
            </button>
            <mat-menu class="dashboard-menu" #menu="matMenu" yPosition="below" xPosition="after">
              <button
                mat-menu-item
                (click)="assignTo(row)"
                *ngIf="this.userService.roleCheck(['Manager', 'Labtech', 'Admin']) && row.parentRow"
                [disabled]="!tabAssign">
                <span class="menu-text">
                  <img src="/assets/icons/User-multiple.svg" alt="File" /> &nbsp; Assign To
                </span>
              </button>
              <button
                mat-menu-item
                *ngIf="sWTButton && this.userService.roleCheck(['Manager', 'Admin']) && row.parentRow"
                (click)="setWeightnTests(row)">
                <span class="menu-text">
                  <img src="/assets/icons/settings-icon.svg" alt="Worksheet" />&nbsp; Set Weights and M/C
                </span>
              </button>
              <button
                mat-menu-item
                [disabled]="(row.status && this.userService.roleCheck(['Labtech'])) || checkForEditEnable(row)"
                *ngIf="this.userService.roleCheck(['Manager', 'Labtech', 'Admin'])"
                (click)="editWorksheet(row)">
                <span class="menu-text">
                  <img src="/assets/icons/New-window-1.svg" alt="Worksheet" />&nbsp; Open Tech Worksheet
                </span>
              </button>
              <button
                mat-menu-item
                *ngIf="this.userService.roleCheck(['Manager', 'Admin']) && row.parentRow"
                (click)="deleteWorksheet(row.id)">
                <span class="menu-text">
                  <img src="/assets/icons/Delete.svg" alt="Delete-icon" />&nbsp; Delete Row
                </span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>
      </mat-table>
    </div>
  </section>
</div>
