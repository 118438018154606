import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appNegativeDecimal]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NegativeDecimalDirective,
      multi: true,
    },
  ],
})
export class NegativeDecimalDirective implements Validator {
  // Regular expression to match positive and negative decimal values
  private decimalPattern = /^[-+]?\d*\.?\d+$/;

  validate(control: AbstractControl): ValidationErrors | null {
    const { value } = control;

    // Check if the value matches the decimal pattern
    if (value && !this.decimalPattern.test(value)) {
      return {
        decimalInvalid: true,
      };
    }
    return null; // Return null if valid
  }
}
