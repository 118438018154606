import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IInstructionCommentData } from 'src/app/shared/models/i-comment-modal';
import { ICompany } from 'src/app/shared/models/i-company.modal';
import { PrecastSampleType } from 'src/app/shared/models/i-precast-sampletypes.modal';
import { ReviewPayload } from 'src/app/shared/models/worksheet-checkbox.model';

@Component({
  selector: 'app-instructions-sample-type',
  templateUrl: './instructions-sample-type.component.html',
  styleUrls: ['./instructions-sample-type.component.scss'],
})
export class InstructionsSampleTypeComponent implements OnInit {
  @Input() data: any;
  @Input() title: string;
  @Input() link: string;
  @Input() enableEditButton: boolean | null;
  @Input() returnUrl: string | null;
  @Input() companyData: ICompany;
  @Input() commentData: ReviewPayload;
  @Input() precastSampleTypes : PrecastSampleType [];
  @Input() Type: any;
  hideShow = false;
  viewForm = false;
  soiltype = false;
  @Output() submitForm: EventEmitter<ReviewPayload> = new EventEmitter<ReviewPayload>();
  form: FormGroup;
  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      commentInfo: this.fb.group({
        comments: [false],
      }),
    });
  }

  ngOnInit(): void {
    this.hideShow = this.companyData?.id === null;
    const editViewSubmission = sessionStorage.getItem('editViewSubmission');
    if (this.data?.id !== null && editViewSubmission === 'true') {
      this.viewForm = true;
    } else if (editViewSubmission === 'true') {
      this.viewForm = true;
    } else {
      this.viewForm = false;
    }

    if (this.Type === 'nativesoil' && this.companyData?.id === null) {
      this.soiltype = false;
    } else if (this.Type === 'precast' && this.companyData?.id === null) {
      this.soiltype = false;
    } else if (this.Type === 'precast' && this.data?.id !== null) {
      this.soiltype = true;
    } else if (this.Type === 'nativesoil' && this.data?.id !== null) {
      this.soiltype = true;
    } else {
      this.soiltype = false;
    }

    if (this.commentData) {
      this.form.patchValue({
        commentInfo: {
          comments: this.commentData?.commentInfo?.comments || false,
        },
      });
    }
    this.form.valueChanges.subscribe((value) => {
      this.submitForm.emit(value);
    });
    this.submitForm.emit(this.form.value);
  }

  getPrecastSampleTypeName(id: string): string {
    const item = this.precastSampleTypes.find((entry:PrecastSampleType) => entry.id === id);
    return item ? item.label : '';
  }
}
