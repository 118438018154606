<!-- smaple table and without checkbox table-->
<div
  class="shade-white review-each-wrapper mat-elevation-z3 review-sample-wrapper"
  *ngIf="(dataSource && showHide) || viewForm">
  <div class="row">
    <div class="col-md-11">
      <div class="block-wrapper">
        <h2>{{ title }}</h2>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <table
        mat-table
        [dataSource]="dataSource"
        id="mat-t"
        class="mat-elevation-z8"
        aria-describedby="sample Information">
        <ng-container *ngFor="let col of headerColumns" [matColumnDef]="col.name">
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="col.name !== 'sampleStatusTypeId'">{{ col.displayName }}</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="col.name !== 'sampleStatusTypeId'">
              <span *ngIf="col.name === 'displayDepth' && element[col.name] !== null">
                {{ getDisplayDepth(element) }}</span>
              <span *ngIf="col.name === 'sampleTypeMasterId' && element[col.name] !== null">
                {{getSampleTypeName(element.sampleTypeMasterId )}}
              </span>              
              <span
              [title]="col.format === 'truncate' && element[col.name] ? element[col.name] : ''"
              *ngIf="col.name !== 'displayDepth' && element[col.name] !== null && col.name !== 'sampleTypeMasterId'"
              [innerHTML]="(element[col.name] ? element[col.name] : '') | format : col.format">
            </span>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>

<!-- smaple table and checkbox table-->
<div
  class="shade-white review-each-wrapper mat-elevation-z3 review-sample-wrapper"
  *ngIf="dataSource && !showHide && !viewForm">
  <div class="row">
    <div class="col-md-12">
      <div class="block-wrapper">
        <h2>{{ title }}</h2>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="formGroup">
        <table
          mat-table
          [dataSource]="dataSource"
          id="mat-t"
          class="mat-elevation-z8"
          aria-describedby="sample Information">
          <ng-container *ngFor="let col of headerColumns" [matColumnDef]="col.name">
            <th mat-header-cell *matHeaderCellDef>{{ col.displayName }}</th>
            <td mat-cell *matCellDef="let element" [ngClass]="getCellClass(col.name, element)">
              <ng-container *ngIf="col.name === 'sampleStatusTypeId'">
                {{ getSampleStatusLabel(element[col.name]) }}
              </ng-container>

              <ng-container *ngIf="col.name !== 'sampleStatusTypeId'">
                <ng-container *ngIf="element.sampleStatusTypeId !== null">
                  <mat-checkbox
                    *ngIf="shouldShowCheckbox()"
                    class="mat-check-cls"
                    color="primary"
                    [formControlName]="getControlName(element, col.name)">
                  </mat-checkbox>
                </ng-container>
                <span *ngIf="col.name === 'sampleTypeMasterId' && element[col.name] !== null">
                  {{ getSampleTypeName(element.sampleTypeMasterId) }}
                </span>

                <span *ngIf="col.name === 'displayDepth' && element[col.name] !== null">
                  {{ getDisplayDepth(element) }}
                </span>
                <span
                  [title]="col.format === 'truncate' ? element[col.name] : ''"
                  *ngIf="col.name !== 'displayDepth' &&  col.name !== 'sampleTypeMasterId'"
                  [innerHTML]="element[col.name] | format : col.format"></span>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="getRowClasses(row)"></tr>
        </table>
      </form>
    </div>
  </div>
</div>
