import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChartDataset } from 'chart.js';
import { SliderDataService } from 'src/app/shared/services/slider-data.service';
import { ExportTableService } from 'src/app/features/service/export-table.service';
import { GeoMapService } from '../../services/geo-map.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss'],
})
export class ModalContentComponent implements OnInit {
  @Output() radiusChange = new EventEmitter<number>();
  selectedSoil: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number };
  soilTypes: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number }[];
  selectedDensity: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number };
  densities: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number }[];
  selectedMoisture: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number };
  moistures: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number }[];
  dryDensityAvg = 0;
  moistureAvg = 0;
  chartData: any;
  filenamelist: any;
  densityDisable = false;
  moistureDisable = false;
  cityName = '';
  selectedCountry = '';
  SelectedState = '';
  SelectSoilType = '';
  selectedSoilType = '';
  sliderValue = 0;
  typeLabel = 'Soil Type';

  toggleValue: boolean = false;
  selectedFile: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number };
  fileList: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number }[];
  fileData: any;

  public lineChartData: ChartDataset[] = [];
  public lineChartLabels: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalContentComponent>,
    private geoMapService: GeoMapService,
    private sliderDataService: SliderDataService,
    private exportFile: ExportTableService,
  ) {}

  ngOnInit(): void {
    this.sliderDataService.sliderValue$.subscribe((value) => {
      this.sliderValue = value;
    });

    this.sliderDataService.selectedSoilType$.subscribe((soilType) => {
      this.selectedSoilType = soilType;
    });

    this.cityName = this.data.city.city;
    this.selectedCountry = this.data.country;
    this.SelectedState = this.data.state;
    this.SelectSoilType = this.data.soiltype;
    this.xplorerData();
  }

  onToggleChange(event: MatSlideToggleChange) {
    this.toggleValue = event.checked;
    if (this.selectedSoilType === 'native-soil' && this.toggleValue === true) {
      this.clearData();
      this.nativeFileRecords();
    } else if (this.selectedSoilType === 'precast' && this.toggleValue === true) {
     this.clearData();
      this.typeLabel = 'Mix Id';
      this.precastFileRecords();
    } else {
      this.clearData();
      this.xplorerData();
    }
  }
  clearData() {
    this.fileList = [];
    this.soilTypes = [];
    this.densities = [];
    this.moistures = [];
    this.lineChartData = [];
  }
  //--------------------------------- Optimized code with DA Start--------
  private createCityObject() {
    return {
      latitude: this.data.city.latitude,
      longitude: this.data.city.longitude,
      radius: this.sliderValue || 50,
    };
  }

  private handleResponse(res: { [x: string]: any; filenames: any }, chartDataKey: string) {
    if (res) {
      this.filenamelist = res.filenames;
      this.chartData = res[chartDataKey];
      this.soilTypes = this.chartData.options;
    }
  }

  xplorerData() {
    const city = this.createCityObject();

    if (this.selectedSoilType === 'native-soil') {
      this.geoMapService.getCityPredictionData(city).subscribe({
        next: (res) => this.handleResponse(res, 'soilType'),
      });
    } else {
      this.typeLabel = 'Mix Id';
      this.geoMapService.getPrecastCityPredictionData(city).subscribe({
        next: (res) => this.handleResponse(res, 'mixid'),
      });
    }
  }

  private handleFileResponse(res: { filename: any }) {
    if (res) {
      this.fileData = res.filename;
      this.fileList = this.fileData.options;
    }
  }

  nativeFileRecords() {
    const city = this.createCityObject();
    this.geoMapService.getPostNativeFilename(city).subscribe({
      next: (res) => this.handleFileResponse(res),
    });
  }

  precastFileRecords() {
    const city = this.createCityObject();
    this.geoMapService.getPostPrecastFilename(city).subscribe({
      next: (res) => this.handleFileResponse(res),
    });
  }

  //--------------------------------- Optimized code with DA End--------
  /*
  xplorerData() {
    const city = {
      latitude: this.data.city.latitude,
      longitude: this.data.city.longitude,
      radius: this.sliderValue ? this.sliderValue : 50,
    };
    if (this.selectedSoilType === 'native-soil') {
      this.geoMapService.getCityPredictionData(city).subscribe({
        next: (res) => {
          if (res) {
            this.filenamelist = res.filenames;
            this.chartData = res.soilType;
            this.soilTypes = this.chartData.options;
          }
        },
      });
    } else {
      this.typeLabel = 'Mix Id';
      this.geoMapService.getPrecastCityPredictionData(city).subscribe({
        next: (res) => {
          if (res) {
            this.filenamelist = res.filenames;
            this.chartData = res.mixid;
            this.soilTypes = this.chartData.options;
          }
        },
      });
    }
  }

  nativeFileRecords() {
    const city = {
      latitude: this.data.city.latitude,
      longitude: this.data.city.longitude,
      radius: this.sliderValue ? this.sliderValue : 50,
    };
    this.geoMapService.getPostNativeFilename(city).subscribe({
      next: (res) => {
        if (res) {
          this.fileData = res.filename;
          this.fileList = this.fileData.options;
        }
      },
    });
  }
  precastFileRecords() {
    const city = {
      latitude: this.data.city.latitude,
      longitude: this.data.city.longitude,
      radius: this.sliderValue ? this.sliderValue : 50,
    };
    this.geoMapService.getPostPrecastFilename(city).subscribe({
      next: (res) => {
        if (res) {
          this.fileData = res.filename;
          this.fileList = this.fileData.options;
        }
      },
    });
  }
*/
  selectFilename() {
    if (this.selectedSoilType === 'native-soil' && this.toggleValue === true) {
      this.soilTypes = this.fileData[this.selectedFile.referenceKey].soilType.options;
    } else if (this.selectedSoilType === 'precast' && this.toggleValue === true) {
      this.soilTypes = this.fileData[this.selectedFile.referenceKey].mixid.options;
    }
  }
  //----------Optimized code start--------------
  selectSoilType() {
    if (this.toggleValue) {
      this.handleSoilTypeSelection(this.selectedSoilType === 'native-soil' ? 'soilType' : 'mixid');
    } else {
      this.handleChartDataSelection();
    }
  }

  handleSoilTypeSelection(type: 'soilType' | 'mixid') {
    const data = this.fileData[this.selectedFile.referenceKey][type][this.selectedSoil.referenceKey].density;
    this.densities = data.options;
    this.moistures = [];
    this.lineChartData = [];
    this.populateLineChartData(data.options, data.graphs);
    this.dryDensityAvg = 0;
    this.moistureAvg = 0;
  }

  handleChartDataSelection() {
    const data = this.chartData[this.selectedSoil.referenceKey].density;
    this.densities = data.options;
    this.moistures = [];
    this.lineChartData = [];
    this.populateLineChartData(data.options, data.graphs);
    this.dryDensityAvg = 0;
    this.moistureAvg = 0;
  }

  populateLineChartData(labels: any[], graphs: any[]) {
    graphs.forEach((graphData, i) => {
      const graphPoint = graphData.x.map((x: any, j: string | number) => ({ x, y: graphData.y[j] }));
      this.lineChartData.push({
        type: 'line',
        data: graphPoint,
        tension: 0.3,
        label: labels[i].label,
      });
    });
  }

  selectDensity() {
    this.moistures = [];
    this.lineChartData = [];
    if (this.toggleValue) {
      this.handleDensitySelection(this.selectedSoilType === 'native-soil' ? 'soilType' : 'mixid');
    } else {
      this.handleChartDensitySelection();
    }
  }

  handleDensitySelection(type: 'soilType' | 'mixid') {
    const data =
      this.fileData[this.selectedFile.referenceKey][type][this.selectedSoil.referenceKey].density[
        this.selectedDensity.referenceKey
      ];
    this.moistures = data.moisture.options;
    this.populateLineChartData(data.moisture.options, data.moisture.graphs);
    this.dryDensityAvg = this.selectedDensity.DryDensity_Avg;
    this.moistureAvg = this.selectedDensity.Moisture_Avg;
  }

  handleChartDensitySelection() {
    const data = this.chartData[this.selectedSoil.referenceKey].density[this.selectedDensity.referenceKey].moisture;
    this.moistures = data.options;
    this.populateLineChartData(data.options, data.graphs);
    this.dryDensityAvg = this.selectedDensity.DryDensity_Avg;
    this.moistureAvg = this.selectedDensity.Moisture_Avg;
  }

  selectMoisture() {
    this.lineChartData = [];
    if (this.toggleValue) {
      this.handleMoistureSelection(this.selectedSoilType === 'native-soil' ? 'soilType' : 'mixid');
    } else {
      this.handleChartMoistureSelection();
    }
  }

  handleMoistureSelection(type: 'soilType' | 'mixid') {
    const data =
      this.fileData[this.selectedFile.referenceKey][type][this.selectedSoil.referenceKey].density[
        this.selectedDensity.referenceKey
      ].moisture;
    const selectedIndex = data.options.findIndex((opt: { label: string }) => opt.label === this.selectedMoisture.label);
    const graphData = data.graphs[selectedIndex];
    this.populateSingleGraphData(graphData, this.selectedMoisture.label);
    this.dryDensityAvg = this.selectedMoisture.DryDensity_Avg;
    this.moistureAvg = this.selectedMoisture.Moisture_Avg;
  }

  handleChartMoistureSelection() {
    const data = this.chartData[this.selectedSoil.referenceKey].density[this.selectedDensity.referenceKey].moisture;
    const selectedIndex = data.options.findIndex((opt: { label: string }) => opt.label === this.selectedMoisture.label);
    const graphData = data.graphs[selectedIndex];
    this.populateSingleGraphData(graphData, this.selectedMoisture.label);
    this.dryDensityAvg = this.selectedMoisture.DryDensity_Avg;
    this.moistureAvg = this.selectedMoisture.Moisture_Avg;
  }

  populateSingleGraphData(graphData: any, label: string) {
    const graphPoint = graphData.x.map((x: any, j: string | number) => ({ x, y: graphData.y[j] }));
    this.lineChartData.push({
      type: 'line',
      data: graphPoint,
      tension: 0.3,
      label,
    });
  }
  //----------Optimized code End--------------
  /*
  selectSoilType() {
    if (this.toggleValue) {
      if (this.selectedSoilType === 'native-soil') {
        this.densities =
          this.fileData[this.selectedFile.referenceKey].soilType[this.selectedSoil.referenceKey].density.options;
        this.moistures = [];
        this.lineChartData = [];
        const graphLabels: any[] =
          this.fileData[this.selectedFile.referenceKey].soilType[this.selectedSoil.referenceKey].density.options;
        const graphDatas: any[] =
          this.fileData[this.selectedFile.referenceKey].soilType[this.selectedSoil.referenceKey].density.graphs;

        graphDatas.forEach((graphData, i) => {
          const graphPoint = [];
          for (let j = 0; j < graphData.x.length; j++) {
            graphPoint.push({
              x: graphData.x[j],
              y: graphData.y[j],
            });
          }
          this.lineChartData.push({
            type: 'line',
            data: graphPoint,
            tension: 0.3,
            label: graphLabels[i].label,
          });
        });
        this.dryDensityAvg = 0;
        this.moistureAvg = 0;
      } else if (this.selectedSoilType === 'precast') {
        this.densities =
          this.fileData[this.selectedFile.referenceKey].mixid[this.selectedSoil.referenceKey].density.options;
        this.moistures = [];
        this.lineChartData = [];
        const graphLabels: any[] =
          this.fileData[this.selectedFile.referenceKey].mixid[this.selectedSoil.referenceKey].density.options;
        const graphDatas: any[] =
          this.fileData[this.selectedFile.referenceKey].mixid[this.selectedSoil.referenceKey].density.graphs;

        graphDatas.forEach((graphData, i) => {
          const graphPoint = [];
          for (let j = 0; j < graphData.x.length; j++) {
            graphPoint.push({
              x: graphData.x[j],
              y: graphData.y[j],
            });
          }
          this.lineChartData.push({
            type: 'line',
            data: graphPoint,
            tension: 0.3,
            label: graphLabels[i].label,
          });
        });
        this.dryDensityAvg = 0;
        this.moistureAvg = 0;
      }
    } else {
      this.densities = this.chartData[this.selectedSoil.referenceKey].density.options;
      this.moistures = [];
      this.lineChartData = [];
      const graphLabels: any[] = this.chartData[this.selectedSoil.referenceKey].density.options;
      const graphDatas: any[] = this.chartData[this.selectedSoil.referenceKey].density.graphs;

      graphDatas.forEach((graphData, i) => {
        const graphPoint = [];
        for (let j = 0; j < graphData.x.length; j++) {
          graphPoint.push({
            x: graphData.x[j],
            y: graphData.y[j],
          });
        }
        this.lineChartData.push({
          type: 'line',
          data: graphPoint,
          tension: 0.3,
          label: graphLabels[i].label,
        });
      });
      this.dryDensityAvg = 0;
      this.moistureAvg = 0;
    }
  }

  selectDensity() {
    this.moistures = [];
    this.lineChartData = [];
    if (this.toggleValue) {
      if (this.selectedSoilType === 'native-soil') {
        const moistureValues =
          this.fileData[this.selectedFile.referenceKey].soilType[this.selectedSoil.referenceKey].density[
            this.selectedDensity.referenceKey
          ];
        this.moistures = moistureValues.moisture.options;
        const graphLabels: any[] =
          this.fileData[this.selectedFile.referenceKey].soilType[this.selectedSoil.referenceKey].density[
            this.selectedDensity.referenceKey
          ].moisture.options;
        const graphDatas: any[] =
          this.fileData[this.selectedFile.referenceKey].soilType[this.selectedSoil.referenceKey].density[
            this.selectedDensity.referenceKey
          ].moisture.graphs;
        graphDatas.forEach((graphData, i) => {
          const graphPoint = [];
          for (let j = 0; j < graphData.x.length; j++) {
            graphPoint.push({
              x: graphData.x[j],
              y: graphData.y[j],
            });
          }
          this.lineChartData.push({
            type: 'line',
            data: graphPoint,
            tension: 0.3,
            label: graphLabels[i].label,
          });
        });

        this.dryDensityAvg = this.selectedDensity.DryDensity_Avg;
        this.moistureAvg = this.selectedDensity.Moisture_Avg;
      } else if (this.selectedSoilType === 'precast') {
        const moistureValues =
          this.fileData[this.selectedFile.referenceKey].mixid[this.selectedSoil.referenceKey].density[
            this.selectedDensity.referenceKey
          ];
        this.moistures = moistureValues.moisture.options;
        const graphLabels: any[] =
          this.fileData[this.selectedFile.referenceKey].mixid[this.selectedSoil.referenceKey].density[
            this.selectedDensity.referenceKey
          ].moisture.options;
        const graphDatas: any[] =
          this.fileData[this.selectedFile.referenceKey].mixid[this.selectedSoil.referenceKey].density[
            this.selectedDensity.referenceKey
          ].moisture.graphs;
        graphDatas.forEach((graphData, i) => {
          const graphPoint = [];
          for (let j = 0; j < graphData.x.length; j++) {
            graphPoint.push({
              x: graphData.x[j],
              y: graphData.y[j],
            });
          }
          this.lineChartData.push({
            type: 'line',
            data: graphPoint,
            tension: 0.3,
            label: graphLabels[i].label,
          });
        });

        this.dryDensityAvg = this.selectedDensity.DryDensity_Avg;
        this.moistureAvg = this.selectedDensity.Moisture_Avg;
      }
    } else {
      this.moistures =
        this.chartData[this.selectedSoil.referenceKey].density[this.selectedDensity.referenceKey].moisture.options;
      const graphLabels: any[] =
        this.chartData[this.selectedSoil.referenceKey].density[this.selectedDensity.referenceKey].moisture.options;
      const graphDatas: any[] =
        this.chartData[this.selectedSoil.referenceKey].density[this.selectedDensity.referenceKey].moisture.graphs;
      graphDatas.forEach((graphData, i) => {
        const graphPoint = [];
        for (let j = 0; j < graphData.x.length; j++) {
          graphPoint.push({
            x: graphData.x[j],
            y: graphData.y[j],
          });
        }
        this.lineChartData.push({
          type: 'line',
          data: graphPoint,
          tension: 0.3,
          label: graphLabels[i].label,
        });
      });

      this.dryDensityAvg = this.selectedDensity.DryDensity_Avg;
      this.moistureAvg = this.selectedDensity.Moisture_Avg;
    }
  }

  selectMoisture() {
    this.lineChartData = [];
    if (this.toggleValue) {
      if (this.selectedSoilType === 'native-soil') {
        const option: any[] =
          this.fileData[this.selectedFile.referenceKey].soilType[this.selectedSoil.referenceKey].density[
            this.selectedDensity.referenceKey
          ].moisture.options;
        const selectedIndex = option.findIndex((opt) => opt.label === this.selectedMoisture.label);
        const graphData: any =
          this.fileData[this.selectedFile.referenceKey].soilType[this.selectedSoil.referenceKey].density[
            this.selectedDensity.referenceKey
          ].moisture.graphs[selectedIndex];
        const graphPoint = [];
        for (let j = 0; j < graphData.x.length; j++) {
          graphPoint.push({
            x: graphData.x[j],
            y: graphData.y[j],
          });
        }
        this.lineChartData.push({
          type: 'line',
          data: graphPoint,
          tension: 0.3,
          label: this.selectedMoisture.label,
        });

        this.dryDensityAvg = this.selectedMoisture.DryDensity_Avg;
        this.moistureAvg = this.selectedMoisture.Moisture_Avg;
      } else if (this.selectedSoilType === 'precast') {
        const option: any[] =
          this.fileData[this.selectedFile.referenceKey].mixid[this.selectedSoil.referenceKey].density[
            this.selectedDensity.referenceKey
          ].moisture.options;
        const selectedIndex = option.findIndex((opt) => opt.label === this.selectedMoisture.label);
        const graphData: any =
          this.fileData[this.selectedFile.referenceKey].mixid[this.selectedSoil.referenceKey].density[
            this.selectedDensity.referenceKey
          ].moisture.graphs[selectedIndex];
        const graphPoint = [];
        for (let j = 0; j < graphData.x.length; j++) {
          graphPoint.push({
            x: graphData.x[j],
            y: graphData.y[j],
          });
        }
        this.lineChartData.push({
          type: 'line',
          data: graphPoint,
          tension: 0.3,
          label: this.selectedMoisture.label,
        });

        this.dryDensityAvg = this.selectedMoisture.DryDensity_Avg;
        this.moistureAvg = this.selectedMoisture.Moisture_Avg;
      }
    } else {
      const option: any[] =
        this.chartData[this.selectedSoil.referenceKey].density[this.selectedDensity.referenceKey].moisture.options;
      const selectedIndex = option.findIndex((opt) => opt.label === this.selectedMoisture.label);
      const graphData: any =
        this.chartData[this.selectedSoil.referenceKey].density[this.selectedDensity.referenceKey].moisture.graphs[
          selectedIndex
        ];
      const graphPoint = [];
      for (let j = 0; j < graphData.x.length; j++) {
        graphPoint.push({
          x: graphData.x[j],
          y: graphData.y[j],
        });
      }
      this.lineChartData.push({
        type: 'line',
        data: graphPoint,
        tension: 0.3,
        label: this.selectedMoisture.label,
      });

      this.dryDensityAvg = this.selectedMoisture.DryDensity_Avg;
      this.moistureAvg = this.selectedMoisture.Moisture_Avg;
    }
  }
*/
  closeDialog(): void {
    this.dialogRef.close();
  }

  downloadData() {
    this.exportFile.downloadTxtFile(
      this.filenamelist,
      this.cityName,
      this.selectedCountry,
      this.SelectedState,
      this.SelectSoilType,
    );
  }
}
