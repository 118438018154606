import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/** Angular Material Imports */
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule }from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
/** Components, Directives, Services Pipes */
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { AdminGridTableComponent } from './components/admin-grid-table/admin-grid-table.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { HeaderComponent } from './components/header/header.component';
import { LandingDialogComponent } from './components/landing-dialog/landing-dialog.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { NotesComponent } from './components/notes/notes.component';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import { SetStatusComponent } from './components/set-status/set-status.component';
import { UiMessageModalComponent } from './components/ui-message-modal/ui-message-modal.component';
import { AlphabeticDirective, AlphanumericDirective } from './directives/alphanumeric.directive';
import { CommaNumberDirective } from './directives/comma-number.directive';
import { CommaSpaceDirective } from './directives/comma-space.directive';
import { DecimalInputDirective } from './directives/decimal.directive';
import { MultiEmailValidatorDirective } from './directives/multi-email-formatter.directive';
import { NumberInputDirective } from './directives/number.directive';
import { NumberDirective } from './directives/numbersonly.directive';
import { OneDigitDecimaNumberDirective } from './directives/one-digit-decimal.directive';
import { RestrictInputDirective } from './directives/restrict-input.directive';
import { DecimalOnlyDirective } from './directives/onlyDecimal.directive';
import { OnlyIntegerDirective } from './directives/onlyInteger.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { RemoveLeadingZeroDirective } from './directives/remove-leading-zero.directive';
import { ScrollDownDirective } from './directives/scroll-down.directive';
import { ProjectCountComponent } from './components/project-count/project-count.component';
import { AdminService } from './services/admin.service';

import { DynamicPipe } from './pipes/dynamic.pipe';
import { PhoneNumberFormat } from './pipes/phone-number.pipe';
import { UtcToLocalPipe } from './pipes/utc-to-local.pipe';
import { DialogService } from './services/dialog.service';
import { NotificationsService } from './services/notifications.service';
import { ManageReportModalComponent } from './components/manage-report-modal/manage-report-modal.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { CustomerAttachmentsComponent } from './components/customer-attachments/customer-attachments.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { AdditionalCommentComponent } from './components/additional-comment/additional-comment.component';
import { MailPreviewComponent } from './components/mail-preview/mail-preview.component';
import { RichtextEditorComponent } from './components/richtext-editor/richtext-editor.component';
import { NegativeDecimalDirective } from './directives/negative-decimal.directive';
import { ThreeDigitOneDecimalDirective } from './directives/three-digit-one-decimal.directive';

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    HeaderComponent,
    LandingDialogComponent,
    MessageModalComponent,
    UiMessageModalComponent,
    NotesComponent,
    ProjectDetailsComponent,
    SetStatusComponent,
    NumberDirective,
    MultiEmailValidatorDirective,
    UtcToLocalPipe,
    PhoneMaskDirective,
    DynamicPipe,
    PhoneNumberFormat,
    CommaNumberDirective,
    AlphanumericDirective,
    NumberInputDirective,
    RemoveLeadingZeroDirective,
    DecimalOnlyDirective,
    OnlyIntegerDirective,
    ScrollDownDirective,
    DecimalInputDirective,
    CommaSpaceDirective,
    OneDigitDecimaNumberDirective,
    RestrictInputDirective,
    ProjectCountComponent,
    AdminGridTableComponent,
    ManageReportModalComponent,
    TruncatePipe,
    CustomerAttachmentsComponent,
    FileSizePipe,
    AdditionalCommentComponent,
    MailPreviewComponent,
    AlphabeticDirective,
    RichtextEditorComponent,
    NegativeDecimalDirective,
    ThreeDigitOneDecimalDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDividerModule,
    DatePipe,
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatTableModule,
    MatSlideToggleModule,
    DragDropModule,
    MatSortModule,
    MatTooltipModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatChipsModule,
    MatAutocompleteModule,
  ],
  exports: [
    ConfirmationDialogComponent,
    HeaderComponent,
    LandingDialogComponent,
    MessageModalComponent,
    NotesComponent,
    ProjectDetailsComponent,
    SetStatusComponent,
    NumberDirective,
    MultiEmailValidatorDirective,
    UtcToLocalPipe,
    PhoneMaskDirective,
    DynamicPipe,
    PhoneNumberFormat,
    CommaNumberDirective,
    AlphanumericDirective,
    NumberInputDirective,
    RemoveLeadingZeroDirective,
    DecimalOnlyDirective,
    OnlyIntegerDirective,
    ScrollDownDirective,
    DecimalInputDirective,
    CommaSpaceDirective,
    OneDigitDecimaNumberDirective,
    RestrictInputDirective,
    ProjectCountComponent,
    AdminGridTableComponent,
    ManageReportModalComponent,
    TruncatePipe,
    CustomerAttachmentsComponent,
    MatTooltipModule,
    AdditionalCommentComponent,
    AlphabeticDirective,
    RichtextEditorComponent,
    NegativeDecimalDirective,
    ThreeDigitOneDecimalDirective,
  ],
  providers: [DialogService, NotificationsService, AdminService],
})
export class SharedModule {}
