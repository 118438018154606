import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { SoilType } from 'src/app/shared/constants/soil-type.constants';
import { ISIGNATURE } from 'src/app/shared/models/i-signature.modal';
import { REPORTPREVIEW } from 'src/app/shared/models/i-report-preview.modal';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-edit-report',
  templateUrl: './edit-report.component.html',
  styleUrls: ['./edit-report.component.scss'],
})
export class EditReportComponent implements OnInit {
  mainBody = '';
  @Input() previewData: REPORTPREVIEW;
  @Input() sigArray: ISIGNATURE[];
  @Input() soilType: string;
  @Input() projectId: string;
  @Input() hasBillingInfo: boolean;
  @Input() figureCompletedCount: number;
  @Input() figureCount: number;
  @Input() exportShow: boolean;
  form: FormGroup;
  previewDisabled = false;
  savedDone = false;
  geothermComment = '';
  @Output() previewClicked: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() savedNotify: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(
    private fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    private snackBar: MatSnackBar,
  ) {
    this.form = this.fb?.group({
      id: [''],
      revisionDate: [''],
      header: [''],
      subHeader: [''],
      reportDate: ['', Validators.required],
      isBilling: [false, Validators.required],
      mainBody: [''],
      geothermComment: [''],
      commentPart1: [''],
      commentPart2: [''],
      signatureTypeId: [''],
      unitType: [''],
    });
  }

  ngOnInit(): void {
    if (this.soilType === SoilType.Precast) {
      this.addNativeControls();
    }
    if (this.soilType === SoilType.Native) {
      this.form.addControl('mainBodyIntro', this.fb.control('', Validators.required));
    }
    if (this.previewData?.mainBody.includes('xxxxx')) {
      const figureText = this.figureCount === 1 ? 'Figure 1' : `Figures 1-${this.figureCount}`;
      this.previewData.mainBody = this.previewData.mainBody.replace('xxxxx', figureText);
    }
    this.form.patchValue(this.previewData);
    this.geothermComment = this.form.get('geothermComment')?.value || '';

    // Update the rich text editor when the form control value changes
    this.form.get('geothermComment')?.valueChanges.subscribe((value) => {
      if (this.geothermComment !== value) {
        this.geothermComment = value;
      }
    });

    this.form?.valueChanges?.subscribe(() => {
      this.previewDisabled = true;
    });
  }

  addNativeControls(): void {
    this.form.addControl('commentPart3', this.fb.control(''));
    this.form.addControl('commentPart4', this.fb.control(''));
    this.form.addControl('commentPart5', this.fb.control(''));
  }

  save(): void {
    this.savedDone = false;
    const geothermCommentValue = this.form.get('geothermComment')?.value;
    if (geothermCommentValue === '<p><br></p>' || geothermCommentValue?.trim() === '<p><br></p>') {
      this.form.get('geothermComment')?.setValue('');
    }
    this.reportService.updateReport(this.form.value)?.subscribe({
      next: () => {
        this.snackBar.open(messagesConfig.generalSubmitSuccessMessage.message, 'DONE', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['default-snackbar'],
        });
        this.reportService?.fetchReportData(this.projectId, this.soilType).subscribe({
          next: (report: REPORTPREVIEW) => {
            this.previewData.updatedAt = report.updatedAt;
            this.previewDisabled = false;
            this.savedDone = true;
            this.savedNoti();
          },
        });
      },
    });
  }

  preview() {
    this.previewClicked.emit(this.savedDone);
    this.savedDone = false;
  }

  savedNoti() {
    this.savedNotify.emit(this.savedDone);
    this.savedDone = false;
  }

  onContentChanged(content: string) {
    this.form.get('geothermComment')?.setValue(content, {
      emitEvent: false,
    });
  }

  onMainBodyChanged(content: string) {
    this.form.patchValue({
      mainBody: content,
    });
  }
}
