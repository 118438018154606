import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { Subscription } from 'rxjs';

import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { INotification } from '../../models/i-notification.modal';
import { NotificationsService } from '../../services/notifications.service';
import { UserService } from '../../services/user.service';
import { PageLoaderService } from '../page-loader/page-loader.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public notificationSubscription = Subscription.EMPTY;
  public userSubscription = Subscription.EMPTY;
  public notifications: INotification[] = [];
  public userAccount: AccountInfo;
  unreadNotificationCount = 0;
  currentRoute = '';
  buttonText = 'Dashboard';
  constructor(
    public notificationsService: NotificationsService,
    public userService: UserService,
    private router: Router,
    public authService: MsalService,
    private pageloaderService: PageLoaderService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
        this.updateButtonText();
      }
    });
  }

  ngOnInit() {
    this.userSubscription = this.userService?.userAccount?.subscribe({
      next: (account) => {
        if (account) {
          this.userAccount = account;
          this.fetchNotifications();
        }
      },
    });
  }

  updateButtonText() {
    if (this.currentRoute.includes('in-house-dashboard')) {
      this.buttonText = 'Dashboard';
    } else if (this.currentRoute.includes('dashboard')) {
      this.buttonText = 'In-House Dashboard';
    } else {
      this.buttonText = 'In-House Dashboard';
    }
  }

  onNotificationDismiss(notification: INotification): void {
    this.notificationSubscription = this.notificationsService?.markNotificationAsRead(notification)?.subscribe({
      next: () => {
        this.fetchNotifications();
      },
    });
  }

  public fetchNotifications() {
    this.notificationSubscription = this.notificationsService?.fetchNotifications()?.subscribe({
      next: (response: INotification[]) => {
        this.unreadNotificationCount = response?.filter((item) => !item?.isRead)?.length;
        this.notifications = response?.sort((a, b) => Number(a?.isRead) - Number(b?.isRead));
      },
    });
  }

  ngOnDestroy(): void {
    this.notificationSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  settings() {
    this.router.navigateByUrl('admin/settings');
  }

  navigateToGeoXplore() {
    this.router.navigateByUrl('geoxplore');
  }

  navigateToInHouseDashborad() {
    if (this.currentRoute.includes('in-house-dashboard')) {
      this.router.navigateByUrl('dashboard');
    } else if (this.currentRoute.includes('dashboard')) {
      this.router.navigateByUrl('in-house-dashboard');
    } else {
      this.router.navigateByUrl('in-house-dashboard');
    }
  }

  navigateToAuditLogReport() {
    this.router.navigateByUrl('audit-log-report');
  }

  reDirectUrl() {
    if (this.currentRoute.includes('dashboard')) {
      window.location.reload();
    }
    else if(this.currentRoute.includes('customer')) {
      this.router.navigateByUrl('customer');
    }
    else{
      this.router.navigateByUrl('dashboard');
    }
  }

  logOut() {
    sessionStorage.clear();
    this.router
      .navigateByUrl('dashboard')
      .then(() => {
        this.authService.logout();
      })
      .finally(() => { });
  }
}
