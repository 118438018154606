/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { messagesConfig } from '../../constants/message.constants';
import { UploadedFile } from '../../models/file-upload.model';
import { Comments, EmailWorksheetInfo } from '../../models/worksheet-checkbox.model';
import { MailPreviewComponent } from '../mail-preview/mail-preview.component';

@Component({
  selector: 'app-additional-comment',
  templateUrl: './additional-comment.component.html',
  styleUrls: ['./additional-comment.component.scss'],
})
export class AdditionalCommentComponent implements OnInit {
  public additionalNotesError = false;
  public additionalNotes: string;
  selectedFiles: File[] = [];
  uploadedFiles: UploadedFile[] = [];
  maxFileSize: number = 10 * 1024 * 1024;
  errorMessage = '';
  contactName: string;
  allInfoData: EmailWorksheetInfo;
  tablepreview: any;
  soiltype: string;
  projectId: string;
  projectName: string;
  projectNo: string;
  instruction_Comments: Comments;
  uploadedFilesList: string[] = [];
  totalFiles = 0;
  completedFiles = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mdr: MatDialogRef<AdditionalCommentComponent>,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.contactName = this.data.contactName;
    this.allInfoData = this.data.fullInfo;
    this.tablepreview = this.data.table;
    this.soiltype = this.data.soilType;
    this.projectId = this.data.projectId;
    this.instruction_Comments = this.data.addComments;
    this.projectName = this.data.projectName;
    this.projectNo = this.data.projectNo;
    const storedFiles = sessionStorage.getItem('uploadedFilesList');
    if (storedFiles) {
      this.uploadedFilesList = JSON.parse(storedFiles);
    }
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLTextAreaElement;
    const currentValue = inputElement.value;
    const validInputRegex = /^[A-Za-z0-9\s!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|`~.-]*$/;
    if (!validInputRegex.test(currentValue)) {
      inputElement.value = currentValue.replace(/[^A-Za-z0-9\s!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|`~.-]/g, '');
    }
    if (currentValue.trim() === '') {
      inputElement.value = '';
    }
    this.additionalNotes = inputElement.value;
    if (this.additionalNotes.trim() === '') {
      this.additionalNotes = '';
    }
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      let totalSize = this.uploadedFiles.reduce((acc, file) => acc + file.file.size, 0);
      this.selectedFiles = [];

      for (let i = 0; i < inputElement.files.length; i++) {
        const file = inputElement.files[i];
        if (this.isValidFile(file)) {
          totalSize += file.size;
          this.selectedFiles.push(file);
        }
      }

      if (totalSize > this.maxFileSize) {
        this.errorMessage = 'The file is too large and cannot be uploaded. Please reduce the file size (Maximum 10MB) and try again.';
        this.selectedFiles = [];
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
      } else {
        this.uploadFiles();
      }
    }
    inputElement.value = '';
  }

  isValidFile(file: File): boolean {
    if (file.size > this.maxFileSize) {
      this.snackBar.open(messagesConfig.fileuploadLimit.message, 'DONE', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['default-snackbar'],
      });
      return false;
    }

    const isDuplicate = this.selectedFiles.some((f) => f.name === file.name)
      || this.uploadedFilesList.includes(file.name);

    if (isDuplicate) {
      this.snackBar.open(messagesConfig.fileuploadDuplicate.message, 'DONE', {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['default-snackbar'],
      });
      return false;
    }

    return true;
  }

  uploadFiles() {
    this.totalFiles = this.selectedFiles.length;
    this.completedFiles = 0;

    this.selectedFiles.forEach((file) => {
      if (!this.uploadedFiles.some((uf) => uf.file.name === file.name)) {
        if (file.size <= this.maxFileSize) {
          this.errorMessage = '';
          this.uploadFile(file);
        }
      }
    });
  }

  uploadFile(file: File) {
    const API_ENDPOINTS = `${environment.baseUrl}notification/uploademailattachment/${this.projectId}`;
    const formData = new FormData();
    formData.append('file', file);
    const uploadedFile: UploadedFile = {
      file,
      progress: 0,
      completed: false,
    };

    this.uploadedFiles.push(uploadedFile);
    this.http.post<any>(API_ENDPOINTS, formData, {
      reportProgress: true,
      observe: 'events',
    }).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((100 * event.loaded) / event.total);
          const uploadedFile = this.uploadedFiles.find((uf) => uf.file.name === file.name);
          if (uploadedFile) {
            uploadedFile.progress = percentDone;
          }
        } else if (event instanceof HttpResponse) {
          if (!this.uploadedFilesList.includes(file.name)) {
            this.uploadedFilesList.push(file.name);
            sessionStorage.setItem('uploadedFilesList', JSON.stringify(this.uploadedFilesList));
          }
          this.completedFiles += 1;
          this.snackBar.open(messagesConfig.apiUploadSuccessMessage.message, 'DONE', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['default-snackbar'],
          });

          const uploadedFile = this.uploadedFiles.find((uf) => uf.file.name === file.name);
          if (uploadedFile) {
            uploadedFile.progress = 100;
            uploadedFile.completed = true;
          } else {
            this.uploadedFiles.push({
              file,
              progress: 100,
              completed: true,
            });
          }
        }
      },
      (error) => {
        this.snackBar.open('Error uploading file', 'CLOSE', {
          duration: 5000,
          verticalPosition: 'top',
          panelClass: ['default-snackbar'],
        });
      },
    );
  }

  deleteFile(fileName: string) {
    const API_ENDPOINT = `${environment.baseUrl}notification/deleteemailattachment/${this.projectId}/${fileName}`;
    return this.http.delete<any>(API_ENDPOINT);
  }

  cancelUpload(file: UploadedFile) {
    const index = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
      const fileNameIndex = this.uploadedFilesList.indexOf(file.file.name);
      if (fileNameIndex !== -1) {
        this.uploadedFilesList.splice(fileNameIndex, 1);
        sessionStorage.setItem('uploadedFilesList', JSON.stringify(this.uploadedFilesList));
      }
      this.deleteFile(file.file.name).subscribe(
        () => {
          this.snackBar.open('File deleted successfully', 'DONE', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['default-snackbar'],
          });
        },
        (error) => {
          this.snackBar.open('Error deleting file', 'CLOSE', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['default-snackbar'],
          });
        },
      );
    }
  }

  closeDialog(): void {
    this.mdr?.close(false);
    sessionStorage.removeItem('uploadedFilesList');
  }

  previewDialog() {
    this.mdr?.close(false);
    const dialogRef = this.dialog.open(MailPreviewComponent, {
      height: 'calc(100% )',
      width: 'calc(100% )',
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'dialog-container-class', // Apply custom CSS class
      backdropClass: 'dialog-backdrop-class',
      data: {
        additionalNotes: this.additionalNotes,
        contactName: this.contactName,
        fullInfo: this.allInfoData,
        table: this.tablepreview,
        soilType: this.soiltype,
        projectId: this.projectId,
        instruction: this.instruction_Comments,
        projectName: this.projectName,
        projectNo: this.projectNo,
        uploadedFilesList: this.uploadedFilesList,
      },
    });
  }
}
